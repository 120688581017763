

import { useEffect, useRef  } from "react";
import React from 'react';
import wellioviz from "alphawellio";
// material
import { Box } from "@mui/material";
import faker from "faker";
var template =  [{
  "curve_box": {
      "show_well_name": "yes", /// not built yet
      "show_depth_type": "no", /// not built yet
      "show_curve_units": "yes", /// not built yet
      "curve_box_depth_min": -999, /// not built yet
      "curve_box_depth_max": -999, /// not built yet
      "take_out_null_or_visualize": "no", /// not built yet
      "show_title": "no",
      "width": 260,
      "height": 500,
      "height_multiplier_components": 1,
      "margin": {"top": 30, "right": 40, "bottom": 30, "left": 30},
      "title": {"text": "", "title_font_size": "10px"}, /// not built yet
      "div_id": "well_holder_3", /// Should be skip-able // default=random str? What happens if div doesn't exist?
      "order_of_component": ["curves", "rectanges", "lines"], /// not built yet
      "lines_connected_across_curveboxes": "no", /// not built yet
      "header_sep_svg_or_not": "yes",
      "svg_header_height": "6em",
      "gridlines": "yes",
      "gridlines_color": "#D3D3D3",
      "gridlines_stroke_width": 0.20,
      "grouped_or_independent_x_scales": "independent",
      //// variables for how to draw mouseover of hover box
      "mouseover_yes_or_no": "no", //// "yes" or "no"
      "mouseover_depth_or_depth_and_curve": "depth_and_curve", /// options= "depth_and_curve", "depth", or "curve"
      "mouseover_curvename": "default", //// default is first curve
      "mouseover_color_or_default_which_is_curve_color": "default" /// default is default, which then uses curve color or black
  },
  "components": [{
      "curves": [
          {
              "data_type": "curve",
              "curve_names": ["DEPT"],
              "curve_colors": ["black"],
              "curve_stroke_dasharray": ["solid"],
              "stroke_linecap": ["butt"],
              "stroke_width": [1],
              "fill": [
                  {}
              ],
              "curve_units": ["g/cm3"],
              "depth_limits": [{"min": "autocalculate", "max": "autocalculate"}],
              "curve_limits": [{"curve_name": "", "min": -10000000, "max": 3}],
              "data": [],
              "depth_curve_name": "DEPT",/// not built yet
              "data_id": ["placeholder_data_id",], /// not built yet
              "well_names": [""], /// not built yet
              "scale_linear_log_or_yours": ["linear"],
              "line_color": [], /// not built yet
              "max_depth": "autocalculate", /// not built yet
              "min_depth": "autocalculate", /// not built yet
              "depth_type_string": [""],
              "depth_units_string": [""],
              "null_value": [""], /// not built yet
          }
      ],
      "lines": [
          {
              "data_type": "line",  /// not built yet
              "label": "example",  /// not built yet
              "depth": -999, /// not built yet
              "color": "red", /// not built yet
              "stroke_width": "3px", /// not built yet
              "stroke_style": "solid", /// not built yet
              "transparency": 1.0, /// not built yet
              "stroke_linecap": "butt"
          }
      ],
      "rectangles": [
          {
              "data_type": "rectangle",
              "depth_top": 0,
              "x_starting_upper_left_corner": 0,
              "width": 0,
              "height": 0,
              "stroke_width": "2px",
              "stroke_linecap": "butt",
              "fill": "red",
              "opacity": 0.5,
              "label": "", // not built into plotting template yet
              "label_orientation": "horizontal", // not built into plotting template yet
              "lable_position": "right" // not built into plotting template yet
          }
      ]
  }]
},];
function calcGridItemPx(
  gridUnits,
  colOrRowSize,
  marginPx
) {
  return Math.round(
    colOrRowSize * gridUnits + Math.max(0, gridUnits - 1) * marginPx
  );
}

export default function Wellog(props) {
  const nbr = 2;
  var database = useRef();
  var dataObject = useRef();
  const [holderId, setHolderId] = React.useState();
  var logRef = React.createRef();
  //console.log("PROPS*****", props);
  var activatedChannels = []

  for (var ch in props.channelsState) {
    if (props.channelsState[ch].checked) {
      activatedChannels.push(props.channelsState[ch].name)
    }
  }
  //console.log("DIMENSIONS", props.dimensions[0]);
  if (props.height && props.width) {
    var h = props.height - 20 //calcGridItemPx(props.dimensions[0].h, props.dimensions[1], 0);
    var w = props.width - 20 //calcGridItemPx(props.dimensions[0].w, props.dimensions[2], 100);
  } else {
    var h = 500;
    var w = 300;
  }

  //console.log("sizes", h, w);

  useEffect(() => {
    var curves = [...activatedChannels];
    curves.push("DEPT");
    //console.log("CURVATURE",  activatedChannels);
    var start = 0;
    var reformated = []
    for (var i=0; i <=2; i++){
      var instance = {}
      for (var ch in activatedChannels){
        instance[activatedChannels[ch]] = 0
      }
      instance["DEPT"] = start + 1
      start = start + 1
      instance["UWI"] = "00/01-01-095-19W4/0"
      reformated.push(instance)
    }
    //console.log("reformated", reformated)
    dataObject.current = {
      "well_log_curves_reformatted_for_d3": reformated,
      "curve_names": curves,
      "uwi": "00/01-01-095-19W4/0"
    }

    template[0]["curve_box"]["width"] = w;
    template[0]["curve_box"]["height"] = h;
    template[0]["curve_box"]["height_multiplier_components"] = 1;
    let template_components = template[0]["components"];
    template[0]["components"][0]["curves"][0]["curve_names"] = curves;
    let template_curves = template_components[0]["curves"][0];
    let curve_names = template_curves["curve_names"];
    var templates = []
    var i = 1;
    var curves_view = []
    for (var channel in  activatedChannels) {
      if (parseInt(channel)+1 ===  activatedChannels.length) {
        var curve2 = ""
      } else {
        var curve2 =  activatedChannels[parseInt(channel)+1]
      }
      curves_view.push({
        curve_name:  activatedChannels[channel],
        fill: "no",
        fill_direction: "left",
        cutoffs: [],
        fill_colors: [],
        curve2: curve2,
      })
    }
    //console.log(curves_view)
    var plot_template_noFill = wellioviz.minimumDataIntoTemplateFunc(
      template,
      dataObject.current.well_log_curves_reformatted_for_d3,
      [dataObject.current.uwi],
      activatedChannels,
      ["black", "blue", "green", "yellow"],
      [""],
      curves_view,
      "well_holder_1_" + i,
      w, // 300
      h, // 500
      "DEPT"
    );
    templates.push(plot_template_noFill);
    i++;
    //}
    var holder_id = faker.datatype.uuid()
    setHolderId(holder_id)
    var result_1 = wellioviz.multipleLogPlot("well_holder"+holderId, templates);
  }, []);

  useEffect(() => {
    var holder_id = ""
    var activatedChannels = []
    for (var ch in props.channelsState) {
      if (props.channelsState[ch].checked) {
        activatedChannels.push(props.channelsState[ch].name)
      }
    }

    //console.log("HOLDER", holder_id)

    if (props.dataPlot !== undefined && Object.keys(props.dataPlot).length !== 0) {
      props.setDownloadableContent({
        type: 'application/json',
        data: props.dataPlot,
      });
      ////// DUMMY DATA /////////
      /*
      var elts = dataObject.current.well_log_curves_reformatted_for_d3
      var last_dep = parseFloat(elts[elts.length - 1]["DEPT"])
      console.log("last depth", last_dep);
      var instance = []
      for (var ch in activatedChannels){
          instance[ activatedChannels[ch]] = Math.round(Math.random()*10)
      }
      instance["DEPT"] = last_dep + 0.250
      instance["UWI"] = "00/01-01-095-19W4/0"
      console.log("instaaaannce", instance)
      dataObject.current.well_log_curves_reformatted_for_d3.push(instance)
      */
      ///////////////////////
      // fill missing columns with 0 value
      for (var inst in props.dataPlot){
        for (var chan in activatedChannels) {
          if (!props.dataPlot[inst][activatedChannels[chan]]){
            props.dataPlot[inst][activatedChannels[chan]] = 0
          }
        }
      }
      //console.log("DATAAAPLOT", props.dataPlot, Object.values(props.dataPlot), Object.values(props.dataPlot).length)
      // wellioviz requires to have at least 3 depths values in the data.
      if (Object.keys(props.dataPlot).length >= 3) {
        //console.log("THIIIIS", Object.values(props.dataPlot))
        dataObject.current.well_log_curves_reformatted_for_d3 = [...Object.values(props.dataPlot)]
      } else {
        //console.log("BEFORETHAT", dataObject.current.well_log_curves_reformatted_for_d3.length)
        dataObject.current.well_log_curves_reformatted_for_d3.push(...Object.values(props.dataPlot));
        //console.log("THAT", dataObject.current.well_log_curves_reformatted_for_d3)
      }
      //console.log("heightt/width", h, w)
      // set space for header based on the number of attributes
      var header_height = 4.5 + (activatedChannels.length - 1) + Math.floor((activatedChannels.length - 1)*0.5)

      template[0]["curve_box"]["svg_header_height"] = header_height.toString()+"em"
      //console.log("header_height", template[0]["curve_box"]["svg_header_height"])
      template[0]["curve_box"]["height_multiplier_components"] = 1;
      var templates = []
      var i = 1;
      var curves_view = []
      for (var channel in  activatedChannels) {
        if (parseInt(channel)+1 ===  activatedChannels.length) {
          var curve2 = ""
        } else {
          var curve2 =  activatedChannels[parseInt(channel)+1]
        }
        //console.log("VIEEEW", curve2, parseInt(channel)+1,  activatedChannels.length, parseInt(channel)+1 ===  activatedChannels.length)
        curves_view.push({
          curve_name:  activatedChannels[channel],
          fill: "no",
          fill_direction: "left",
          cutoffs: [],
          fill_colors: [],
          curve2: curve2,
        })
      }
      //console.log("CVIEEW", curves_view);
      var plot_template_noFill = wellioviz.minimumDataIntoTemplateFunc(
        template,
        dataObject.current.well_log_curves_reformatted_for_d3,
        [dataObject.current.uwi],
        activatedChannels,
        ["black", "blue", "green", "yellow"],
        [""],
        curves_view,
        "well_holder_1_" + i,
        w,
        h,
        "DEPT"
      );
      //console.log("TEMMMPPPPS", plot_template_noFill[0]["components"][0]["curves"][0]["data"])
      //console.log("UPDATE HEIGHT", plot_template_noFill[0]["curve_box"]["width"], plot_template_noFill[0]["curve_box"]["height"])
      plot_template_noFill[0]["curve_box"]["width"] = plot_template_noFill[0]["curve_box"]["width"] - 50
      plot_template_noFill[0]["curve_box"]["height"] = plot_template_noFill[0]["curve_box"]["height"] - 100
      plot_template_noFill[0]["curve_box"]["margin"] = {"top": 30, "right": 40, "bottom": 30 + header_height*10, "left": 30}
      //console.log(plot_template_noFill[0]["curve_box"]["width"], plot_template_noFill[0]["curve_box"]["height"])
      templates.push(plot_template_noFill);
      i++;
      //}
      var result_1 = wellioviz.multipleLogPlot("well_holder"+holderId, templates);
      //console.log("SVG ELEMENT", logRef.current);
    }
  }, [props.dataPlot, props.dimensions]);


     return (
            <Box m={1} >
            <div ref={logRef} className={"well_holder"+holderId} id={"well_holder"+holderId}>
            </div>
            </Box>
    );
}
