/* eslint-disable array-callback-return */
// react
import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
// Styles --------------------------------------------------
const DataWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflow: "auto",
  backgroundColor: theme.palette.grey[300],
  padding: theme.spacing(0.7),
  borderRadius: theme.spacing(0.7),
  marginTop: theme.spacing(0.5),
}));

export default function ScalerWidget(props) {
  // states -----------------------------------------------------
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  
  const newRows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];
  // functions --------------------------------------------------
  const generateColumns = (data) => {
    let tmpColumns = [];
    Object.keys(data).map((key) => {
      var channel = data[key];
      if (channel.checked) {
        tmpColumns.push({
          field: channel.name,
          headerName: channel.name,
          width: 150,
          channelId: channel.channelId,
        });
      }
      return null;
    });
    // console.log("tmpColumns: ", tmpColumns);
    return tmpColumns;
  };
  const generateRows = (data) => {
    let tmpRows = [];
    if (!data) {
      // console.log("no data");
      return tmpRows;
    }
    Object.keys(data).map((rowKey) => {
      var row = data[rowKey];
      // console.log("row: ", row);
      var tmpRow = {
        id: rowKey,
      };
      // loop through the columns and check if they exist in the row
      Object.keys(columns).map((key) => {
        var column = columns[key];
        if (row[column.channelId]) {
          tmpRow[column.field] = row[column.channelId];
        } else {
          tmpRow[column.field] = "";
        }
        return null;
      });
      tmpRows.push(tmpRow);
      return null;
    });
    // console.log("tmpRows: ", tmpRows);
    return tmpRows;
  };
  // useEffect --------------------------------------------------
  React.useEffect(() => {
    // console.log("Data : ", props);
    setColumns(generateColumns(props.channelsState));
    setRows(generateRows(props.data));
    // console.log("****columns: ", columns);
    // console.log("****rows: ", rows);
  }, [props]);

  // return ------------------------------------------------------
  // generate a table with the data from the states
  return (
    <DataWrapper style={{ width: "100%", overflow:"auto" }}>
      <table>
        <thead>
          <tr>
            {columns.map((column) => {
              return <th key={column.field}>{column.headerName}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            return (
              <tr key={row.id}>
                {columns.map((column) => {
                  return <td key={column.field}>{row[column.channelId]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </DataWrapper>
  );
}
