// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppWellTrackerInfo,
  AppFormatConverterSettings,
} from "../components/_dashboard/app";
import DropFileInput from "../components/DropFileInput";
// ----------------------------------------------------------------------
const onFileChange = (files) => {
  // console.log(files);
};
export default function FormatConverter() {
  return (
    <Page title="Format Converter | Alpha PGS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Track your wells</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <AppWellTrackerInfo />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <DropFileInput onFileChange={(files) => onFileChange(files)} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Container>
              <Grid item xs={12} md={12} lg={12}>
                <AppFormatConverterSettings />
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
