// React
import React, { useState, useRef, useEffect, Fragment } from "react";
// material
import { styled } from "@mui/material/styles";
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
// components
import Page from "../components/Page";
import Widget from "../components/widgets/Widget";
import AddWidget from "../components/monitoringCards/AddWidget";
import ConfigBoard from "../components/monitoringCards/ConfigBoard";
// import the fixed tab bar
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

import NotificationsPopover from "../layouts/dashboard/NotificationsPopover";
// Grid
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import "../assets/css/react-grid-layout.css";
import "../assets/css/widget.css";
// storage
import * as storage from "../utils/storage";
// screenShot libraries
// notification
import { useSnackbar } from "notistack";
// faker
import faker from "faker";

// etp
import { Buffer } from "buffer";
import * as etp_tools from "../utils/etp_tools";
import { useNavigate } from "react-router-dom";
import HealthBar from "./HealthBar";
const etp = require("etp");
const ReactGridLayout = WidthProvider(RGL);

// Styles  --------------------------------------------------------------
const WidgetStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  height: "100%",
}));
const useAudio = (url, mute) => {
  const [audio] = useState(new Audio(url));
  const [isMute, setIsMute] = useState(mute);
  const [playing, setPlaying] = useState(false);
  // state of the last time the audio was played
  const [lastPlayed, setLastPlayed] = useState(0);
  const [playedOnce, setPlayedOnce] = useState(false);

  const toggleAudio = () => {
    // check if the last time the audio was played is more than 10 seconds ago
    // console.log("isMute", isMute);
    if (!isMute && ((Date.now() - lastPlayed > 4000) || !playedOnce)) {
      setPlaying(true);
    }
  };
  const toggleMute = (variable) => {
    // console.log("toggleMute", variable);
    setIsMute(variable);
  };

  useEffect(() => {
    playing ? audio.play() : audio.pause();
    setLastPlayed(new Date());
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [playing, toggleAudio, toggleMute];
};
export default function MonitoringView() {
  // loading from local storage ------------------------------------------
  var conf = storage.getFromLS("streaming-config");
  // states --------------------------------------------------------------
  const [showHealth, setShowHealth] = useState(true);
  const [mute, setMute] = useState(false);
  const [numberOfColumns, setNumberOfColumns] = React.useState(16);
  const [rowHeight, setRowHeight] = React.useState(60);
  const [waitingChannelSets, setWaitingChannelSets] = useState({});
  const [channels, setChannels] = React.useState([]);
  const [trajectory, setTrajectory] = React.useState({});
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openConfig, setOpenConfig] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [streamingData, setStreamingData] = React.useState({});
  const [rangeRequestData, setRangeRequestData] = React.useState({});
  const [streamingRowsData, setStreamingRowsData] = React.useState({});
  const [rangeRequestRowsData, setRangeRequestRowsData] = React.useState({});
  const [streamingLog, setStreamingLog] = React.useState({});
  const [rangeRequestLog, setRangeRequestLog] = React.useState({});
  const [alertRanges, setAlertRanges] = React.useState({});
  const [rangeRequestStatus, setRangeRequestStatus] = React.useState({});
  const [localUuid, setLocalUuid] = React.useState(
    new etp_tools.BidirectionalMap({})
  );
  const [localIdName, setLlocalIdName] = React.useState(
    new etp_tools.BidirectionalMap({})
  );
  const [openDialog, setOpenDialog] = React.useState(false);
  const [layout, setLayout] = useState([]);
  const [widgets, setWidgets] = useState({});
  const [widgetsCount, setWidgetsCount] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const [audio, toggleAudio, toggleMute] = useAudio(
    "/static/audio/notification.wav",
    mute
  );
  const [resizeCount, setResizeCount] = useState(0);
  const [totalNumberOfDataPackages, setTotalNumberOfDataPackages] = useState(0);
  const [lastPackage, setLastPackage] = useState({
    timestamp: 'no package',
    index: 0,
  });
  // navigation ----------------------------------------------------------
  const navigate = useNavigate();
  // refs ---------------------------------------------------------------
  const socket = useRef(null);
  const self = React.createRef(null);
  const notificationIcon = React.createRef(null);
  // Screenshot ----------------------------------------------------------
  // snackbar ------------------------------------------------------------
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // constants ------------------------------------------------------------
  const defaultProps = {
    className: "layout",
    cols: numberOfColumns,
    rowHeight: rowHeight,
    items: 0,
    width: 1500,
  };
  // functions ------------------------------------------------------------
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);
  const handleOpenConfig = () => setOpenConfig(true);
  const handleCloseConfig = () => setOpenConfig(false);
  const onLayoutChange = (newLayout) => {
    for (let i = 0; i < newLayout.length; i++) {
      newLayout[i].h = layout[i].h;
      newLayout[i].w = layout[i].w;
      newLayout[i].maxH = layout[i].maxH;
      newLayout[i].maxW = layout[i].maxW;
      newLayout[i].minH = layout[i].minH;
      newLayout[i].minW = layout[i].minW;
      newLayout[i].static = layout[i].static;
      newLayout[i].isDraggable = layout[i].isDraggable;
      newLayout[i].isResizable = layout[i].isResizable;
      newLayout[i].resizeHandles = layout[i].resizeHandles;
      newLayout[i].isBounded = layout[i].isBounded;
    }
    setLayout(newLayout);
  };
  const onResize = (newLayout, oldItem, newItem, placeholder, e, element) => {
    setLayout(newLayout);
    setResizeCount(resizeCount + 1);
  };
  const removeItem = (id) => {
    // remove the item from the widgets
    if (layout.length === 1) {
      setLayout([]);
      setWidgets({});
      return;
    }
    const newWidgets = _.omit(widgets, id);
    setWidgets(newWidgets);
    //removing the element from the layout
    setLayout(layout.filter((item) => item.i !== id));
    defaultProps.items = defaultProps.items - 1;
  };
  const addItem = (item) => {
    // split the item into the widget and the layout
    let tmp = splitWidgetLayout(generateItem(item));
    let tmpWidget = tmp[1];
    let tmpLayout = tmp[0];
    let checkedChannels = [];
    Object.keys(tmpWidget.channelsState).forEach((key) => {
      if (tmpWidget.channelsState[key].checked) {
        checkedChannels.push(tmpWidget.channelsState[key].channelId);
      }
    });
    if (!tmpWidget.isStreaming && tmpWidget.range.start < tmpWidget.range.end) {
      var channelInfos = [];
      for (let i = 0; i < checkedChannels.length; i++) {
        var ch =
          new etp.Energistics.Datatypes.ChannelData.ChannelStreamingInfo();
        ch.channelId = [checkedChannels[i]];
        ch.startIndex = tmpWidget.range.start;
        ch.endIndex = tmpWidget.range.end;
        channelInfos.push(ch);
      }
      rangeRequestData[tmpWidget.i] = {};
      etp_tools.sendRangeRequest(socket.current, channelInfos, tmpWidget.i);
      // set the range request status
      rangeRequestStatus[tmpWidget.i] = false;
    }
    // update the widgets
    widgets[tmpWidget.i] = tmpWidget;
    setWidgets(widgets);
    // add the new layout
    layout.push(tmpLayout);
    setLayout(layout);
    defaultProps.items = defaultProps.items + 1;
  };
  const generateItem = (props) => {
    props.i = widgetsCount.toString();
    props.x = 0;
    props.y = 0;
    props.static = false;
    if (props.type === "scaler") {
      // props.isResizable = false;
      props.w = 3;
      props.h = 3;
      // props.minW = 3;
      // props.minH = 3;
    } else if (props.type === "log") {
      props.w = 4;
      props.h = 3;
      // props.minW = 4;
      // props.minH = 3;
    } else if (props.type === "chart") {
      props.w = 4;
      props.h = 10;
    } else {
      props.w = 4;
      props.h = 3;
      // props.minW = 4;
      // props.minH = 3;
    }
    setWidgetsCount(widgetsCount + 1);
    return props;
  };
  const splitWidgetLayout = (item) => {
    let tmpLayout = {
      i: item.i,
    };
    let tmpWidget = {
      i: item.i,
    };
    // loop over the attribute of item if the attribute is in [x, y, w, h, minW, minH, maxW, maxH, moved, static, isDraggable, isResizable, isBounded] add it to the tmpLayout else add it to the tmpWidget
    for (let key in item) {
      if (
        key === "x" ||
        key === "y" ||
        key === "w" ||
        key === "h" ||
        key === "minW" ||
        key === "minH" ||
        key === "maxW" ||
        key === "maxH" ||
        key === "moved" ||
        key === "static" ||
        key === "isDraggable" ||
        key === "isResizable" ||
        key === "isBounded"
      ) {
        tmpLayout[key] = item[key];
      } else {
        tmpWidget[key] = item[key];
      }
    }
    return [tmpLayout, tmpWidget];
  };
  const startStreaming = () => {
    closeSnackbar();
    // start the streaming
    // getting channels with allowStream = true, channels is an object
    let channelsToStream = [];
    for (let channel in channels) {
      if (channels[channel].allowStream) {
        channelsToStream.push(channels[channel].channelId);
      }
    }
    var channelInfos = [];
    for (let i = 0; i < channelsToStream.length; i++) {
      var ch = new etp.Energistics.Datatypes.ChannelData.ChannelStreamingInfo();
      ch.channelId = [channelsToStream[i]];
      //ch.startIndex = 0;
      //ch.endIndex = 20;
      channelInfos.push(ch);
    }
    etp_tools.sendStartStreaming(socket.current, channelInfos);
    //etp_tools.sendRangeRequest(socket.current, channelInfos, 5);
  };
  const etpHandler = (socket, msg_header, msg_message) => {
    //console.log("etpHandler:", msg_header, msg_message);
    if (msg_header.protocol === 1) {
      let channelSetsUUIDS = [];
      if (msg_header.messageType === 2) {
        for (var i = 0; i < msg_message.channels.length; i++) {
          let channelUUID =
            msg_message.channels[i].channelUri.split("/Channel(")[1];
          channelUUID = channelUUID.split(")")[0];
          let channelSetUUID =
            msg_message.channels[i].channelUri.split("/ChannelSet(")[1];
          channelSetUUID = channelSetUUID.split(")")[0];
          if (!channelSetsUUIDS.includes(channelSetUUID)) {
            channelSetsUUIDS.push(channelSetUUID);
          }
          channels[channelUUID]["channelId"] =
            msg_message.channels[i].channelId;
          channels[channelUUID]["dataType"] = msg_message.channels[i].dataType;
          channels[channelUUID]["indexes"] = msg_message.channels[i].indexes;
          channels[channelUUID]["status"] = msg_message.channels[i].status;
          channels[channelUUID]["uom"] = msg_message.channels[i].uom;
          localUuid.add([
            msg_message.channels[i].channelId,
            msg_message.channels[i].channelUri,
          ]);
          setLocalUuid(localUuid);
          // create correspondance channelId == channel
          localIdName.add([
            msg_message.channels[i].channelId,
            msg_message.channels[i].channelName,
          ]);
          setLlocalIdName(localIdName);
          // initialize channel alerts
          var cid = msg_message.channels[i].channelId;
          if (!alertRanges[cid]) {
            alertRanges[cid] = 0;
          }
          setAlertRanges(alertRanges);
        }
        setChannels(channels);

        for (var i = 0; i < channelSetsUUIDS.length; i++) {
          let channelSetUUID = channelSetsUUIDS[i];
          if (waitingChannelSets[channelSetUUID]) {
            waitingChannelSets[channelSetUUID] = false;
            setWaitingChannelSets(waitingChannelSets);
          }
        }
        let countWaitingChannelSets = 0;
        for (let key in waitingChannelSets) {
          if (waitingChannelSets[key]) {
            countWaitingChannelSets++;
          }
        }
        if (countWaitingChannelSets === 0) {
          closeSnackbar();
          setOpenDialog(true);
          // const action = (
          //   <Fragment>
          //     <Button onClick={startStreaming}>
          //       <strong>Okay</strong>
          //     </Button>
          //     <Button onClick={() => navigate("/app/wells")}>
          //       <strong>No</strong>
          //     </Button>
          //   </Fragment>
          // );
          // enqueueSnackbar(
          //   "Loaded streaming meta-data successfully. Allow data streaming:",
          //   {
          //     persist: true,
          //     preventDuplicate: true,
          //     action,
          //   }
          // );
        }
      }
      if (msg_header.messageType === 3) {
        var nbrOfPackes = totalNumberOfDataPackages + 1
        setTotalNumberOfDataPackages(nbrOfPackes);
        // streaming mode
        let msg_data = msg_message.data;
        let tmpStreamingData = { ...streamingData };
        let tmpRangeRequestData = { ...rangeRequestData };
        let tmpStreamingRowsData = { ...streamingRowsData };
        let tmpRangeRequestRowsData = { ...rangeRequestRowsData };
        let tmpStreamingLog = { ...streamingLog };
        let tmpRangeRequestLog = { ...rangeRequestLog };
        for (let i = 0; i < msg_data.length; i++) {
          let channelId = msg_data[i].channelId;
          let indexes = msg_data[i].indexes;
          //
          let indexZero = indexes[0];
          if (msg_header.correlationId === 0 && indexes.length === 2) {
            indexZero = indexes[1];
          }
          // max between indexZero and lastPackageIndex
          let index = indexZero;
          if (index < lastPackage.index) {
            index = lastPackage.index;
          }
          setLastPackage({
            timestamp: new Date().toISOString(),
            index: index,
          })
          let value = msg_data[i].value.item;
          let tmpVal = 0;
          if (Object.keys(value)[0] === "double") {
            if (value.double > 50) {
              tmpVal = parseFloat(value.double.toFixed(0));
            } else {
              tmpVal = value.double.toFixed(2);
            }
            tmpVal = parseFloat(tmpVal);
          } else if (
            Object.keys(value)[0] === "Energistics.Datatypes.ArrayOfDouble"
          ) {
            tmpVal = "(";
            for (
              let i = 0;
              i < value["Energistics.Datatypes.ArrayOfDouble"].values.length;
              i++
            ) {
              if (value["Energistics.Datatypes.ArrayOfDouble"].values[i] > 50) {
                tmpVal +=
                  value["Energistics.Datatypes.ArrayOfDouble"].values[
                    i
                  ].toFixed(0);
              } else {
                tmpVal +=
                  value["Energistics.Datatypes.ArrayOfDouble"].values[
                    i
                  ].toFixed(2);
              }
              if (
                i !==
                value["Energistics.Datatypes.ArrayOfDouble"].values.length - 1
              ) {
                tmpVal += " | ";
              }
            }
            tmpVal += ")";
          }
          // check if tmpVal is within the range min-max
          if (msg_header.correlationId === 0) {
            var channelUri = localUuid.get(channelId);
            let channelUuid = channelUri.split("/Channel(")[1];
            channelUuid = channelUuid.split(")")[0];
            for (var chan in conf.channels) {
              let confUuid = conf.channels[chan].id.split("/Channel(")[1];
              confUuid = confUuid.split(")")[0];
              if (confUuid === channelUuid) {
                let uninitialized =
                  conf.channels[chan].maxVal === -1 &&
                  conf.channels[chan].minVal === -1;
                if (uninitialized) break;
                let outOfRange =
                  parseFloat(tmpVal) > parseFloat(conf.channels[chan].maxVal) ||
                  parseFloat(tmpVal) < parseFloat(conf.channels[chan].minVal);
                if (outOfRange) {
                  // play sound
                  toggleAudio();
                  if (alertRanges[channelId] !== 1) {
                    alertRanges[channelId] = 1
                  }
                  // generate a new notification
                  let notification = {
                    id: faker.datatype.uuid(),
                    title: "Out of range",
                    description:
                      "The value of " +
                      conf.channels[chan].name +
                      " is out of range.",
                    avatar: null,
                    type: "mail",
                    createdAt: new Date(),
                    isUnRead: true,
                  };
                  // insert the notification into the top of the notifications array
                  let tmpNotifications = [notification, ...notifications];
                  setNotifications(tmpNotifications);
                } else if (alertRanges[channelId] === 1) {
                  alertRanges[channelId] = 0;
                }
                setAlertRanges(alertRanges)
                break;
              }
            }
          }
          // get time with millisecond precision
          let time = new Date();
          // convert to year, month, day, hour, minute, second, millisecond from time
          let year = time.getFullYear();
          let month = time.getMonth() + 1;
          let day = time.getDate();
          let hour = time.getHours();
          let minute = time.getMinutes();
          let second = time.getSeconds();
          let millisecond = time.getMilliseconds();
          let stringDate = `${year}-${month}-${day} ${hour}:${minute}:${second}.${millisecond}`;
          if (msg_header.correlationId === 0) {
            // Streaming log data
            if (!tmpStreamingLog[stringDate]) {
              tmpStreamingLog[stringDate] = {};
            }
            if (!tmpStreamingLog[stringDate][indexZero]) {
              tmpStreamingLog[stringDate][indexZero] = {};
            }
            tmpStreamingLog[stringDate][indexZero][channelId] = tmpVal;
            setStreamingLog(tmpStreamingLog);
            //console.log("NEW VALUE TO LOG", indexZero, localIdName.get(channelId), tmpVal, streamingLog)
            // Streaming data
            if (!tmpStreamingData[channelId]) {
              tmpStreamingData[channelId] = {};
            }
            tmpStreamingData[channelId][indexZero] = tmpVal;
            // Streaming rows data
            if (!tmpStreamingRowsData[indexZero]) {
              tmpStreamingRowsData[indexZero] = {};
              tmpStreamingRowsData[indexZero]["DEPT"] = indexZero;
              tmpStreamingRowsData[indexZero]["UWI"] = "00/01-01-095-19W4/0";
            }
            tmpStreamingRowsData[indexZero][localIdName.get(channelId)] = tmpVal;
            setStreamingRowsData(tmpStreamingRowsData);
            //console.log("NEW VALUE TO CHART", indexZero, localIdName.get(channelId), parseFloat(tmpVal), streamingRowsData)
          } else {
            //console.log("range for: ", msg_header.correlationId);
            // Range request log data
            if (!tmpRangeRequestLog[msg_header.correlationId]) {
              tmpRangeRequestLog[msg_header.correlationId] = {};
            }
            if (!tmpRangeRequestLog[msg_header.correlationId][stringDate]) {
              tmpRangeRequestLog[msg_header.correlationId][stringDate] = {};
            }
            if (
              !tmpRangeRequestLog[msg_header.correlationId][stringDate][
                indexZero
              ]
            ) {
              tmpRangeRequestLog[msg_header.correlationId][stringDate][
                indexZero
              ] = {};
            }
            if (
              !tmpRangeRequestLog[msg_header.correlationId][stringDate][
                indexZero
              ]
            ) {
              tmpRangeRequestLog[msg_header.correlationId][stringDate][
                indexZero
              ] = [];
            }
            tmpRangeRequestLog[msg_header.correlationId][stringDate][indexZero][
              channelId
            ] = tmpVal;
            setRangeRequestLog(tmpRangeRequestLog);
            // Range request data
            if (!tmpRangeRequestData[msg_header.correlationId]) {
              tmpRangeRequestData[msg_header.correlationId] = {};
            }
            if (!tmpRangeRequestData[msg_header.correlationId][channelId]) {
              tmpRangeRequestData[msg_header.correlationId][channelId] = {};
            }
            tmpRangeRequestData[msg_header.correlationId][channelId][
              indexZero
            ] = tmpVal;
            tmpRangeRequestData[msg_header.correlationId][channelId][
              indexZero
            ] = tmpVal;
            // Range request rows data
            if (!tmpRangeRequestRowsData[msg_header.correlationId]) {
              tmpRangeRequestRowsData[msg_header.correlationId] = {};
            }
            if (!tmpRangeRequestRowsData[msg_header.correlationId][indexZero]) {
              tmpRangeRequestRowsData[msg_header.correlationId][indexZero] = {};
            }
            tmpRangeRequestRowsData[msg_header.correlationId][indexZero][
              localIdName.get(channelId)
            ] = tmpVal;
            tmpRangeRequestRowsData[msg_header.correlationId][indexZero]["DEPT"] = indexZero;
            tmpRangeRequestRowsData[msg_header.correlationId][indexZero]["UWI"] = "00/01-01-095-19W4/0";
            setRangeRequestRowsData(tmpRangeRequestRowsData);
          }
        }
        if (msg_header.correlationId === 0) {
          setStreamingData(tmpStreamingData);
        } else {
          if (msg_header.messageFlags === 3) {
            var tmpRangeRequestStatus = { ...rangeRequestStatus };
            tmpRangeRequestStatus[msg_header.correlationId] = true;
            setRangeRequestStatus(tmpRangeRequestStatus);
          }
        }
      }
    }
  };
  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };
  const handleMarkAsRead = (notification) => {
    setNotifications(
      notifications.map((item) =>
        item.id === notification.id ? { ...item, isUnRead: false } : item
      )
    );
  };
  // UseEffects ---------------------------------------------------------------
  useEffect(() => {
    enqueueSnackbar("Loading...", {
      variant: "info",
      persist: true,
      preventDuplicate: true,
    });
    // load the sever configuration
    const server = storage.getFromLS("server");
    // console.log("server", storage.getFromLS("server"));
    // if the streaming config.channels is empty or undefined, show an error notification
    if (conf.channels === undefined || conf.channels.length === 0) {
      closeSnackbar();
      const action = (
        <Fragment>
          <Button onClick={() => navigate("/app/wells")} color="inherit">
            <strong>Go back</strong>
          </Button>
        </Fragment>
      );
      enqueueSnackbar("No channels available", {
        variant: "error",
        persist: true,
        preventDuplicate: true,
        action,
      });
    }
    setTrajectory(conf.trajectory);
    if (server.url) {
      socket.current = new WebSocket(server.url, "energistics-tp", server.ip, {
        "etp-encoding": "binary",
        Authorization:
          "Basic " + new Buffer.alloc(64, "user:pass").toString("base64"),
      });
      socket.current.onerror = function (msg) {
        // console.log("onerror", msg);
        enqueueSnackbar("Error connecting to the server", {
          variant: "error",
        });
        navigate("/login", { replace: true });
      };
      socket.current.onclose = function (msg) {
        enqueueSnackbar("Connection closed", {
          variant: "error",
        });
        navigate("/login", { replace: true });
      };
      socket.current.onopen = function (msg) {
        etp_tools.sendRequestSession(socket.current);
        // uri example: eml://witsml20/ChannelSet(be28ebaa-ce38-4e67-9086-412aa91cf2a5)/Log(3df60b15-fb13-46a5-8c5a-1a8b4d0471cf)/Well(0b9a906f-1dca-4961-9b7c-4ed0852d8aae)/Wellbore(928280fe-c963-4bfd-b66b-000fc5bde063)/Channel(c104289e-2239-4d45-b610-47a6ba78bdef)
        var channelSets = [];
        var channelsFromConfig = {};
        if (conf.channels !== undefined && conf.channels.length !== 0) {
          Object.keys(conf.channels).forEach((key) => {
            var channelUri = conf.channels[key]["id"];
            // get the channelset uri from the channel uri
            var channelSetUri = channelUri.split("/ChannelSet(")[1];
            channelSetUri = channelSetUri.split(")/")[0];
            if (!channelSets.includes(channelSetUri)) {
              channelSets.push(channelSetUri);
              waitingChannelSets[channelSetUri] = true;
            }
            // getting the channel id from the channel uri
            var channelId = channelUri.split("/Channel(")[1];
            channelId = channelId.split(")")[0];
            channelsFromConfig[channelId] = conf.channels[key];
          });
          setChannels(channelsFromConfig);
          setWaitingChannelSets(waitingChannelSets);

          var wellUri = conf.channels[0]["id"].split("/Well(")[1];
          wellUri = wellUri.split(")/")[0];

          var wellboreUri = conf.channels[0]["id"].split("/Wellbore(")[1];
          wellboreUri = wellboreUri.split(")/")[0];

          etp_tools.sendStart(socket.current, 1, 1);
          var uris = [];
          for (var i = 0; i < channelSets.length; i++) {
            var uri =
              "eml://witsml20/Well(" +
              wellUri +
              ")" +
              "/Wellbore(" +
              wellboreUri +
              ")" +
              "/ChannelSet(" +
              channelSets[i] +
              ")";
            uris.push(uri);
          }
          etp_tools.sendDescribe(socket.current, uris);
        }
      };
    } else {
      enqueueSnackbar("Server not found", {
        variant: "error",
      });
      navigate("/login", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    socket.current.onmessage = function (msg) {
      etp_tools.parseMsg(socket.current, msg, etpHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    streamingData,
    rangeRequestData,
    streamingLog,
    rangeRequestLog,
    rangeRequestStatus,
    streamingRowsData,
    rangeRequestRowsData,
    localIdName,
    channels,
    waitingChannelSets,
    notifications,
    alertRanges,
  ]);
  useEffect(() => {
    toggleMute(mute);
  }, [mute]);
  useEffect(() => {
    // loop over the layout
    var tmpLayout = [];
    //
    for (var i = 0; i < layout.length; i++) {
      //set the width to the minimum between the item width and numberOfColumns
      var item = layout[i]
      item.w = Math.min(item.w, numberOfColumns);
      // check if the item width + item x is bigger than the number of columns
      if (item.w + item.x > numberOfColumns) {
        // send the item to the next row
        item.x = 0;
        item.y += 5;
      }
      tmpLayout.push(item);
    }
    setLayout(tmpLayout);
    setResizeCount(resizeCount + 1);
  }, [rowHeight, numberOfColumns]);
  // Return -------------------------------------------------------------------
  return (
    <Page title="Operations Monitor | Alpha PGS">
      <Container maxWidth="4000px" ref={self}>
        <Grid container spacing={3}>
          <HealthBar
            alerts={alertRanges}
            showHealth={showHealth}
            widgets={Object.keys(widgets).length}
            nbrOfChannels={Object.keys(streamingData).length}
            nbrOfPackages={totalNumberOfDataPackages}
            lastPackage={lastPackage}
            meta={localIdName.fwdMap}
          ></HealthBar>
          <Grid item xs={12} md={12} lg={12}>
            {Object.keys(widgets).length === 0 ? (
              <Box display="flex" justifyContent="center" m={1}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  color={"gray"}
                  sx={{ fontWeight: "bold", my: "30vh" }}
                >
                  <Button color="secondary" onClick={() => handleOpenAdd()}>
                    <Typography
                      variant="h4"
                      sx={{
                        textDecoration: "underline",
                      }}
                    >
                      Add widgets
                    </Typography>
                  </Button>
                  to the dashboard to see the data from the server.
                </Typography>
              </Box>
            ) : (
              <ReactGridLayout
                onLayoutChange={onLayoutChange}
                onResizeStop={onResize}
                {...defaultProps}
              >
                {Object.entries(widgets).map((widget) => {
                  // get the layout item that has i = widget.i
                  var layoutItem = null;
                  for (var i = 0; i < layout.length; i++) {
                    if (layout[i].i === widget[0]) {
                      layoutItem = layout[i];
                      break;
                    }
                  }
                  if (layoutItem === null) {
                    // console.log("layoutItem is null", widget);
                    return null;
                  }
                  // console.log("layoutItem", layoutItem);
                  return (
                    <WidgetStyle
                      key={widget[0]}
                      data-grid={layoutItem}
                    >
                      <Widget
                        id={widget[1].i}
                        removeItem={() => removeItem(widget[1].i)}
                        title={widget[1].title}
                        type={widget[1].type}
                        channels={channels}
                        defaultChannel={widget[1].defaultChannel}
                        channelsState={widget[1].channelsState}
                        meta={localIdName}
                        isStreaming={widget[1].isStreaming}
                        index={widget[1].index}
                        resize={resizeCount}
                        alertRanges={alertRanges}
                        // if widget.isStreaming is true, the widget will receive streamingData otherwise it will receive the rangeRequestData
                        data={
                          widget[1].isStreaming
                            ? streamingData
                            : rangeRequestData[widget[1].i]
                        }
                        rowsData={
                          widget[1].isStreaming
                            ? streamingRowsData
                            : rangeRequestRowsData[widget[1].i]
                        }
                        dataLog={
                          widget[1].isStreaming
                            ? streamingLog
                            : rangeRequestLog[widget[1].i]
                        }
                        range={widget[1].range}
                        dimensions={[
                          layoutItem,
                          rowHeight,
                          numberOfColumns,
                        ]}
                        localIdName={localIdName}
                      />
                    </WidgetStyle>
                  );
                })}
              </ReactGridLayout>
            )}
          </Grid>
        </Grid>
      </Container>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <AddWidget
          open={openAdd}
          channels={channels}
          handleClose={handleCloseAdd}
          meta={localIdName}
          addItem={addItem}
        />
        <ConfigBoard
          open={openConfig}
          setNumberOfColumns={setNumberOfColumns}
          numberOfColumns={numberOfColumns}
          setRowHeight={setRowHeight}
          rowHeight={rowHeight}
          showHealth={showHealth}
          setShowHealth={setShowHealth}
          setMute={(e) => {
            toggleMute();
            setMute(e);
          }}
          mute={mute}
          handleClose={handleCloseConfig}
        />
        <Dialog open={openDialog} onClose={() => navigate("/app/wells")}>
          <DialogTitle id="alert-dialog-title">
            Metadata loaded successfully
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We have loaded the metadata for the selected channels. The next
              step consists of data-streaming which will happen in the
              background and you will be able to see the data in real time. You
              may add more widgets to the dashboard, or you can close this
              dialog and return to the previous page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => navigate("/app/wells")}>Disagree</Button>
            <Button
              onClick={() => {
                startStreaming();
                setOpenDialog(false);
              }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Add widgets"
            icon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => {
              handleOpenAdd();
            }}
          />
          <BottomNavigationAction
            label="Settings"
            icon={<SettingsOutlinedIcon />}
            onClick={() => {
              handleOpenConfig();
            }}
          />
          <BottomNavigationAction
            ref={notificationIcon}
            label="Notification"
            icon={
              <NotificationsPopover
                handleMarkAllAsRead={handleMarkAllAsRead}
                handleMarkAsRead={handleMarkAsRead}
                notifRef={notificationIcon}
                notifications={notifications}
              />
            }
          />
          <BottomNavigationAction
            label="Logout"
            icon={<LogoutRoundedIcon />}
            onClick={() => {
              navigate("/login", { replace: true });
            }}
          />
        </BottomNavigation>
      </Paper>
    </Page>
  );
}
