// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import { LoginForm } from "../components/authentication/login";


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  background: "url(/static/mock-images/covers/pgs.jpg) no-repeat center center",
  height: "100%",
  backgroundSize: "cover",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  padding: "2rem",
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  background: "rgba(255,255,255, 0.75)",
  backdropFilter: "blur(30px)",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(10 , 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | Alpha PGS">
      <Container maxWidth="sm">
        <ContentStyle>
          <SectionStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to Alpha PGS
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Enter your details below.
              </Typography>
            </Stack>
            <LoginForm />
          </SectionStyle>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
