import * as Yup from "yup";
import { useState, createRef } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// etp
import { Buffer } from "buffer";
// storage
import * as storage from "../../../utils/storage";
// notification
import { useSnackbar } from "notistack";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [refs] = useState({
    ip: createRef(),
    port: createRef(),
    email: createRef(),
    password: createRef(),
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [server, setServer] = useState("");
  const [ip] = useState("");
  const [port] = useState("");
  const [email] = useState("");
  const [password] = useState("");
  const handleChange = (event) => {
    setServer(event.target.value);
    if (event.target.value === "Local") {
      formik.setFieldValue("ip", "localhost");
      formik.setFieldValue("port", 9999);
      formik.setFieldValue("email", "user@localhost.here");
      formik.setFieldValue("password", "pass");
    } else if (event.target.value === "Alpha") {
      formik.setFieldValue("ip", "164.92.229.71");
      formik.setFieldValue("port", 9999);
      formik.setFieldValue("email", "user@localhost.here");
      formik.setFieldValue("password", "pass");
    }
  };
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    ip: Yup.string().required("Host IP is required"),
    port: Yup.number().required("Host Port is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: email,
      password: password,
      ip: ip,
      port: port,
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      closeSnackbar();
      enqueueSnackbar("Connecting...", {
        variant: "info",
        persist: true,
      });
      // get the server info
      var server = formik.values.ip;
      var port = formik.values.port;
      // generate websocket url
      var url = "ws://" + server + ":" + port + "/etp";
      console.log(url);
      const socket = new WebSocket(url, "energistics-tp", server, {
        "etp-encoding": "binary",
        Authorization:
          "Basic " + new Buffer.alloc(64, "user:pass").toString("base64"),
      });
      //----------------------------------
      socket.onopen = function (evt) {
        closeSnackbar();
        // store the server info to local storage
        storage.saveToLS("server", {
          ip: server,
          port: port,
          url: url,
        });
        enqueueSnackbar("Connected to the server successfully", {
          variant: "success",
          autoHideDuration: 3000,
        });
        socket.close();
        // redirect to /app/main after 3 seconds
        setTimeout(() => {
          navigate("/app/wells", { replace: true });
        }, 1000);
      };
      //---------------------------------
      socket.onerror = function (evt) {
        closeSnackbar();
        enqueueSnackbar("Error connecting to the server", {
          variant: "error",
          persist: true,
        });
      };
      return false;
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Server</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={server}
              label="Server"
              onChange={handleChange}
            >
              <MenuItem value={"Local"}>Local</MenuItem>
              <MenuItem value={"Alpha"}>Alpha On Digital Ocean</MenuItem>
              <MenuItem value={"Alger"}>Alger</MenuItem>
              <MenuItem value={"Hassi messaoud"}>Hassi messaoud</MenuItem>
              <MenuItem value={"hassi r'mel"}>hassi r'mel</MenuItem>
              <MenuItem value={"Skikda"}>Skikda</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            autoComplete="ip"
            type={"text"}
            label="Host ip"
            value={ip}
            ref={refs.ip}
            {...getFieldProps("ip")}
            error={Boolean(touched.ip && errors.ip)}
            helperText={touched.ip && errors.ip}
          />
          <TextField
            fullWidth
            autoComplete="port"
            type={"number"}
            label="Host Port"
            value={port}
            ref={refs.port}
            {...getFieldProps("port")}
            error={Boolean(touched.port && errors.port)}
            helperText={touched.port && errors.port}
          />
        </Stack>

        <Divider sx={{ my: 3 }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            AND
          </Typography>
        </Divider>

        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            value={email}
            ref={refs.email}
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            value={password}
            ref={refs.password}
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
