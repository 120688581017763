// react
import React from "react";
// screenShot libraries
import { useScreenshot, createFileName } from "use-react-screenshot";
// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Typography,
  Box,
  Button,
  Modal,
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Stack,
  FormControlLabel,
  Switch,
  FormGroup,
} from "@mui/material";
// icons
import SettingsIcon from "@mui/icons-material/Settings";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
// widgets -------------------------------------------------------------
import LogWidget from "src/components/widgets/LogWidget";
import Wellog from "src/components/widgets/Wellog";
import ScalerWidget from "src/components/widgets/ScalerWidget";
import { ConstructionOutlined } from "@mui/icons-material";
import DataGridWidget from "src/components/widgets/DataGridWidget";
// ----------------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(2),
  height: "100%",
}));
const ModelStyle = styled(Card)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  backdropFilter: "blur(15px)",
  padding: theme.spacing(3.5),
  boxShadow: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  border: "1px solid #c8bbf7",
}));
const ContentWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(0, 0, 2, 0),
  width: "100%",
  height: "100%",
}));
// ----------------------------------------------------------------------

export default function Widget(props) {
  // console.log("props widget: ", props);
  // states -------------------------------------------------------------
  // const [id, setId] = React.useState(props.id);
  const [title, setTitle] = React.useState(props.title);
  const [showTitle, setShowTitle] = React.useState(false);
  const [type, setType] = React.useState(props.type);
  const [open, setOpen] = React.useState(false);
  const [channels, setChannels] = React.useState(props.channels);
  const [data, setData] = React.useState({});
  const [dataLog, setDataLog] = React.useState({});
  // const [dataChart, setDataChart] = React.useState({});
  const [rowsData, setRowsData] = React.useState({});
  const [metadata, setMetadata] = React.useState(props.meta);
  const [defaultChannel, setDefaultChannel] = React.useState(
    props.defaultChannel
  );
  const [channelsState, setChannelsState] = React.useState(props.channelsState);
  const [downloadableContent, setDownloadableContent] = React.useState({
    type: "text/plain",
    data: "",
  });
  const [alertRanges, setAlertRanges] = React.useState(props.alertRanges);
  // Refs ---------------------------------------------------------------
  const self = React.createRef(null);
  // Screenshot ----------------------------------------------------------
  const [image, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });
  // functions ------------------------------------------------------------
  // Functions ------------------------------------------------------------
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const download = (
    image,
    { name = "alpha-pgs-" + title, extension = "jpg" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };
  const downloadScreenshot = () => takeScreenshot(self.current).then(download);
  // add a function to download the data from the downloadableContent
  const downloadData = () => {
    const a = document.createElement("a");
    if (downloadableContent.type === "text/plain") {
      a.download = createFileName("txt", "alpha-pgs-" + title + "-data");
    } else if (type === "application/json") {
      a.download = createFileName("json", "alpha-pgs-" + title + "-data");
      downloadableContent.data = JSON.stringify(downloadableContent.data);
    }
    a.href = URL.createObjectURL(
      new Blob([downloadableContent.data], { type: type })
    );
    a.click();
  };
  function calcGridItemPx(gridUnits, colOrRowSize, marginPx) {
    return Math.round(
      colOrRowSize * gridUnits + Math.max(0, gridUnits - 1) * marginPx
    );
  }

  function dataTransform(data) {
    var target = {};
    for (var d in data) {
      // console.log(data[d]);
      for (var c in data[d]) {
        // console.log(data[d][c]);
        if (!target[c]) {
          target[c] = {};
        }
        target[c][props.meta.get(d)] = data[d][c];
        target[c]["DEPT"] = parseInt(c);
        target[c]["UWI"] = "00/01-01-095-19W4/0";
      }
    }
    return target;
  }
  // UseEffects ------------------------------------------------------------
  React.useEffect(() => {
    setData(props.data);
    setDataLog(props.dataLog);
    //setDataChart(props.dataChart);
    setRowsData(props.rowsData);
    setAlertRanges(props.alertRanges);
  }, [props]);
  // ---------
  // get the width and height of the component
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  React.useEffect(() => {
    if (self.current) {
      var widthVal = self.current.offsetWidth;
      var heightVal = self.current.offsetHeight;
      // console.log("props.dimensions: ", props.dimensions);
      if (widthVal < 100) {
        widthVal = calcGridItemPx(
          props.dimensions[0].w,
          props.dimensions[1],
          0
        );
      }
      if (heightVal < 100) {
        heightVal = calcGridItemPx(
          props.dimensions[0].h,
          props.dimensions[1],
          0
        );
      }
      // console.log("OFFSET", widthVal, heightVal);
      setWidth(widthVal);
      setHeight(heightVal);
    }
  }, [props.resize]);
  // Return ---------------------------------------------------------------
  return (
    <>
      <RootStyle ref={self}>
        <Grid
          container
          spacing={0.5}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-center"
        >
          {showTitle && title !== "" && (
            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
          )}
          <Grid item className="widget-tools">
            <Box>
              {(type === "log") && (
                <IconButton
                  color="primary"
                  aria-label="take a screenshot"
                  component="span"
                  onClick={downloadData}
                >
                  <FileDownloadRoundedIcon sx={{ fontSize: 20 }} />
                </IconButton>
              )}

              <IconButton
                color="primary"
                aria-label="take a screenshot"
                component="span"
                onClick={downloadScreenshot}
              >
                <CameraAltRoundedIcon sx={{ fontSize: 20 }} />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="settings"
                component="span"
                onClick={handleOpen}
              >
                <SettingsIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <ContentWrapper>
          {type === "scaler" && (
            <ScalerWidget
              channelName={
                metadata !== null && metadata !== undefined
                  ? metadata.get(defaultChannel)
                  : ""
              }
              channelId={defaultChannel}
              data={data[defaultChannel]}
              flash={
                alertRanges[defaultChannel]
                  ? alertRanges[defaultChannel]
                  : false
              }
            />
          )}
          {type === "table" && (
            <DataGridWidget channelsState={channelsState} data={rowsData} setDownloadableContent={setDownloadableContent} />
          )}
          {type === "log" && <LogWidget logs={dataLog} meta={metadata} setDownloadableContent={setDownloadableContent}/>}
          {type === "chart" && (
            <Wellog
              dataPlot={rowsData} // or {dataTransform(data)}
              dimensions={props.dimensions}
              width={width}
              height={height}
              channelsState={channelsState}
              channels={props.channels}
              localIdName={props.localIdName}
              setDownloadableContent={setDownloadableContent}
            ></Wellog>
          )}
        </ContentWrapper>
      </RootStyle>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelStyle>
          <Box>
            <Grid
              container
              spacing={0.5}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-center"
              sx={{ mb: 3 }}
            >
              <Typography variant="h6">{title} configuration</Typography>
              <IconButton
                color="primary"
                component="span"
                onClick={handleClose}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
            <Typography>
              Configure {title} widget, set a new name, type, and select
              attributes.
            </Typography>
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Widget properties
              </Typography>
            </Divider>
            <Stack spacing={3}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  autoComplete="title"
                  type="text"
                  label="Widget title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormControl>

              <FormControlLabel
                key={"show-title"}
                control={<Switch checked={showTitle} />}
                label={"Show the title"}
                onChange={(event) => {
                  setShowTitle(event.target.checked);
                }}
              />
              {/* <FormControl fullWidth disabled>
                <InputLabel id="type-label">Widget types</InputLabel>
                <Select
                  labelId="type-label"
                  id="type-select"
                  value={type}
                  label="Type"
                  onChange={(event) => {
                    // setType(event.target.value);
                  }}
                >
                  <MenuItem value={"scaler"}>Scaler</MenuItem>
                  <MenuItem value={"chart"}>Chart</MenuItem>
                  <MenuItem value={"table"}>Table</MenuItem>
                  <MenuItem value={"log"}>Log</MenuItem>
                </Select>
              </FormControl> */}
            </Stack>
            {type === "scaler" && (
              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Data attributes
                </Typography>
              </Divider>
            )}
            {type === "scaler" && (
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel id="defaultChannel-label">Attribute</InputLabel>
                  <Select
                    labelId="defaultChannel-label"
                    id="defaultChannel-select"
                    value={defaultChannel}
                    label="Default attribute"
                    onChange={(event) => {
                      setDefaultChannel(event.target.value);
                    }}
                  >
                    {Object.keys(channels).map((key) => {
                      if (channelsState[key].allowStream) {
                        return (
                          <MenuItem value={channels[key].channelId} key={key}>
                            {channels[key].name}
                          </MenuItem>
                        );
                      } else return null;
                    })}
                  </Select>
                </FormControl>
              </Stack>
            )}
            {/* {type === "chart" && (
              <Stack spacing={3}>
                <FormGroup>
                  {Object.keys(channelsState).map((channel, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Switch checked={channelsState[channel].checked} />
                        }
                        label={channel.name}
                        onChange={(event) => {
                          channel.checked = event.target.checked;
                          setChannelsState(channelsState);
                        }}
                      />
                    );
                  })}
                </FormGroup>
              </Stack>
            )} */}
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ mt: 3 }}
              onClick={() => {
                handleClose();
              }}
            >
              Save
            </Button>
            {/* cancel button */}
            <Button
              fullWidth
              size="large"
              color="error"
              sx={{ mt: 1 }}
              onClick={() => {
                // ask for confirmation using a prompt
                const confirmed = window.confirm(
                  "Are you sure you want to reset the widget {" + title + "} ?"
                );
                if (confirmed) {
                  props.removeItem();
                  handleClose();
                }
              }}
            >
              Remove widget
            </Button>
          </Box>
        </ModelStyle>
      </Modal>
    </>
  );
}
