const etp = require("etp");
const avro = require("etp-avro");
const Core = etp.Energistics.Protocol.Core;
const Streaming = etp.Energistics.Protocol.ChannelStreaming;
const Discovery = etp.Energistics.Protocol.Discovery;
const Store = etp.Energistics.Protocol.Store;
const PROTOCOL = etp.Energistics.Datatypes.Protocols;

// Core
export function sendRequestSession(connection) {
  var supportedProtocol = new etp.Energistics.Datatypes.SupportedProtocol();
  var supportedProtocols = []; // should be Array<Energistics.Datatypes.SupportedProtocol>
  var version = new etp.Energistics.Datatypes.Version();
  version.major = 2;
  version.minor = 0;
  supportedProtocol.role = "producer";
  supportedProtocol.protocol = 1;
  supportedProtocol.protocolVersion = version;
  supportedProtocols.push(supportedProtocol);

  supportedProtocol = new etp.Energistics.Datatypes.SupportedProtocol();
  supportedProtocol.role = "store";
  supportedProtocol.protocol = 3;
  supportedProtocol.protocolVersion = version;
  supportedProtocols.push(supportedProtocol);

  supportedProtocol = new etp.Energistics.Datatypes.SupportedProtocol();
  supportedProtocol.role = "store";
  supportedProtocol.protocol = 4;
  supportedProtocol.protocolVersion = version;
  supportedProtocols.push(supportedProtocol);

  var header = generateHeader(PROTOCOL.Core, Core.MsgRequestSession, 0, 0, 0);
  var message = new etp.Energistics.Protocol.Core.RequestSession();
  message.requestedProtocols = supportedProtocols;
  message.applicationName = "";
  message.applicationVersion = "3.0";
  var dataToSend = generateMsg(header, message);
  connection.send(dataToSend);
}

// Discovery
export function sendGetRessource(connection, uri) {
  var header = generateHeader(
    PROTOCOL.Discovery,
    Discovery.MsgGetResources,
    0,
    0,
    0
  );
  var getRessource = new etp.Energistics.Protocol.Discovery.GetResources();
  getRessource.uri = uri;
  var data = generateMsg(header, getRessource);
  connection.send(data);
}

// Streaming
export function sendDescribe(connection, uris) {
  var header = generateHeader(
    PROTOCOL.ChannelStreaming,
    Streaming.MsgChannelDescribe,
    0,
    0,
    0
  );
  var describe =
    new etp.Energistics.Protocol.ChannelStreaming.ChannelDescribe();
  describe.uris = uris;
  connection.send(generateMsg(header, describe));
}

export function sendStart(connection, msgRate, dataItems) {
  var header = generateHeader(
    PROTOCOL.ChannelStreaming,
    Streaming.MsgStart,
    0,
    0,
    0
  );
  var start = new etp.Energistics.Protocol.ChannelStreaming.Start();
  start.maxMessageRate = msgRate;
  start.maxDataItems = dataItems;
  connection.send(generateMsg(header, start));
}

export function sendStartStreaming(connection, channelInfos) {
  var header = generateHeader(
    PROTOCOL.ChannelStreaming,
    Streaming.MsgChannelStreamingStart,
    0,
    0,
    0
  );
  var streamingStart =
    new etp.Energistics.Protocol.ChannelStreaming.ChannelStreamingStart();
  streamingStart.channels = channelInfos;
  connection.send(generateMsg(header, streamingStart));
}

export function sendRangeRequest(connection, channelsInfos, correlationId) {
  // console.log("*******sendRangeRequest", channelsInfos);
  var header = generateHeader(
    PROTOCOL.ChannelStreaming,
    Streaming.MsgChannelRangeRequest,
    correlationId,
    correlationId,
    0
  );
  var rangeRequest =
    new etp.Energistics.Protocol.ChannelStreaming.ChannelRangeRequest();
  rangeRequest.channelRanges = channelsInfos;
  connection.send(generateMsg(header, rangeRequest));
}

// Store
export function sendGetObject(connection, uri) {
  var header = generateHeader(PROTOCOL.Store, Store.MsgGetObject, 0, 0, 0);
  var getObj = new etp.Energistics.Protocol.Store.GetObject();
  getObj.uri = uri;
  connection.send(generateMsg(header, getObj));
}

export function generateHeader(protocol, msgType, msgId, corrId, msgFlags) {
  return {
    protocol: protocol,
    messageType: msgType,
    messageId: msgId,
    correlationId: corrId,
    messageFlags: msgFlags,
  };
}

export function generateMsg(header, message) {
  var schemaCache = new etp.SchemaCache();
  var schemaName = schemaCache.find(
    header.protocol,
    header.messageType
  ).fullName;

  var encoder = new avro.BinaryWriter(schemaCache);
  encoder.writeDatum("Energistics.Datatypes.MessageHeader", header);
  encoder.writeDatum(schemaName, message);
  var dataToSend = encoder.getArrayBuffer();
  return dataToSend;
}

export function readMsg(msg) {
  var msg_header = null;
  var msg_message = null;
  var schemaCache = new etp.SchemaCache();

  if (typeof msg.data == "object") {
    var reader = new avro.BinaryReader(schemaCache, new Uint8Array(msg.data));

    msg_header = reader.readDatum("Energistics.Datatypes.MessageHeader");
    msg_message = reader.readDatum(
      schemaCache.find(msg_header.protocol, msg_header.messageType)
    );
    return [msg_header, msg_message];
  }
}

export const parseMsg = (socket, msg, cb) => {
  var msg_header = null;
  var msg_message = null;
  var schemaCache = new etp.SchemaCache();
  if (typeof msg.data == "object") {
    var buffer = null;
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      buffer = Buffer.from(fileReader.result);
      buffer.readUInt8(1);
      var reader = new avro.BinaryReader(schemaCache, buffer);
      msg_header = reader.readDatum("Energistics.Datatypes.MessageHeader");
      msg_message = reader.readDatum(
        schemaCache.find(msg_header.protocol, msg_header.messageType)
      );
      cb(socket, msg_header, msg_message);
    };
    fileReader.readAsArrayBuffer(msg.data);
  } else {
    var data = JSON.parse(msg.data);
    msg_header = data[0];
    msg_message = data[1];
    return { header: msg_header, message: msg_message };
  }
};

export class BidirectionalMap {
  fwdMap = {}
  revMap = {}

  constructor(map) {
      this.fwdMap = { ...map }
      this.revMap = Object.keys(map).reduce(
          (acc, cur) => ({
              ...acc,
              [map[cur]]: cur,
          }),
          {}
      )
  }

  get(key) {
      return this.fwdMap[key] || this.revMap[key]
  }

  add(pair) {
    this.fwdMap[pair[0]] = pair[1]
    this.revMap[pair[1]] = pair[0]
  }
}