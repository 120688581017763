// react
import React from "react";
// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Typography,
  Box,
  Button,
  Modal,
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Stack,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
// icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// ----------------------------------------------------------------------
const ModalContainerStyle = styled(Modal)({
  position: "absolute",
  overflow: "scroll",
  height: "100%",
  display: "block",
});

const ModalStyle = styled(Card)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  backdropFilter: "blur(15px)",
  padding: theme.spacing(3.5),
  boxShadow: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  border: "1px solid #c8bbf7",
}));

// ----------------------------------------------------------------------
const generateRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export default function AddWidget(props) {
  // States --------------------------------------------------------------
  const [title, setTitle] = React.useState("Widget-" + generateRandomString(5));
  const [type, setType] = React.useState("scaler");
  const [channels, setChannels] = React.useState({});
  const [defaultChannel, setDefaultChannel] = React.useState(-1);
  const [channelsState, setChannelsState] = React.useState({});
  const [countSelected, setCountSelected] = React.useState(0);
  const [isStreaming, setIsStreaming] = React.useState(true);
  const [range, setRange] = React.useState({ start: 0, end: 0 });
  const [index, setIndex] = React.useState("Depth");
  // Functions ------------------------------------------------------------
  const reset = () => {
    setTitle("Widget-" + generateRandomString(5));
    setType("scaler");
    setDefaultChannel(-1);
    var tmpChannelsState = { ...channelsState };
    Object.keys(tmpChannelsState).forEach(function (key) {
      tmpChannelsState[key] = {
        name: key,
        checked: false,
        allowStream: tmpChannelsState[key]["allowStream"],
        channelId: tmpChannelsState[key].channelId,
      };
    });
    setChannelsState(tmpChannelsState);
    setCountSelected(0);
    setIsStreaming(true);
    setRange({ start: 0, end: 0 });
    setIndex("Depth");
  };
  // UseEffects ------------------------------------------------------------
  React.useEffect(() => {
    // console.log("AddWidget.jsx: useEffect");
    let tmpChannels = { ...props.channels };
    setChannels(tmpChannels);
    setDefaultChannel("");
    var newChannels = {};
    var ch = tmpChannels;
    Object.keys(ch).forEach(function (key) {
      newChannels[key] = {
        name: ch[key]["name"],
        checked: false,
        allowStream: ch[key].allowStream,
        channelId: ch[key].channelId,
      };
    });
    setChannelsState(newChannels);
  }, [props.channels]);
  // Return ----------------------------------------------------------------
  return (
    <ModalContainerStyle
      open={props.open}
      onClose={() => {
        props.onClose();
        reset();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalStyle>
        <Grid
          container
          spacing={0.5}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-center"
          sx={{ mb: 3 }}
        >
          <Typography variant="h6">Adding a new widget</Typography>
          <IconButton
            color="primary"
            component="span"
            onClick={() => {
              reset();
              props.handleClose();
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Grid>
        <Grid container spacing={2} direction="row" justify="center">
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                autoComplete="title"
                type="text"
                label="Widget name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Widget type
          </Typography>
        </Divider>
        <Grid
          container
          spacing={0.5}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-center"
          sx={{ mb: 3 }}
        >
          <Grid item xs={6} md={3} lg={3}>
            <div
              className={
                type === "scaler"
                  ? "type-element-root active"
                  : "type-element-root"
              }
              onClick={() => {
                setIndex("Depth");
                setType("scaler");
              }}
            >
              <div className="type-element-child type-scaler"></div>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <div
              className={
                type === "chart"
                  ? "type-element-root active"
                  : "type-element-root"
              }
              onClick={() => {
                setIndex("Depth");
                setType("chart");
              }}
            >
              <div className="type-element-child type-chart"></div>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <div
              className={
                type === "table"
                  ? "type-element-root active disabled"
                  : "type-element-root disabled"
              }
              onClick={() => {
                // setIndex("Depth");
                // setType("table");
              }}
            >
              <div className="type-element-child type-table"></div>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <div
              className={
                type === "log"
                  ? "type-element-root active"
                  : "type-element-root"
              }
              onClick={() => {
                setIndex("Depth");
                setType("log");
              }}
            >
              <div className="type-element-child type-log"></div>
            </div>
          </Grid>
        </Grid>
        {type === "scaler" && (
          <>
            <Typography variant="body2" sx={{ color: "text.secondary", my: 3 }}>
              Note: This widget only support steaming attributes
            </Typography>
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Widget properties
              </Typography>
            </Divider>
            <Stack spacing={3}>
              <FormControl fullWidth>
                <InputLabel id="defaultChannel-label">Attribute</InputLabel>
                <Select
                  labelId="defaultChannel-label"
                  id="defaultChannel-select"
                  value={defaultChannel}
                  label="Default attribute"
                  onChange={(event) => {
                    setDefaultChannel(event.target.value);
                  }}
                >
                  {Object.keys(channels).map((key) => {
                    if (channels[key]["allowStream"]) {
                      return (
                        <MenuItem value={channels[key]["channelId"]} key={key}>
                          {channels[key]["name"]}
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Select>
              </FormControl>
            </Stack>
          </>
        )}
        {(type === "chart" || type === "table" || type === "log") && (
          <>
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Widget properties
              </Typography>
            </Divider>
            <Stack spacing={3}>
              <FormControl fullWidth>
                <InputLabel id="streamingType-label">
                  Streaming or range data
                </InputLabel>
                <Select
                  labelId="streamingType-label"
                  id="streamingType-select"
                  value={isStreaming}
                  label="Streaming or range data"
                  onChange={(event) => {
                    setIsStreaming(event.target.value);
                    if (event.target.value) {
                      setIndex("Depth");
                    }
                  }}
                >
                  <MenuItem value={true}>Streaming</MenuItem>
                  <MenuItem value={false}>Range</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            {!isStreaming && (
              <>
                <Stack spacing={3} sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="streamingType-label">
                      Time or depth index
                    </InputLabel>
                    <Select
                      labelId="streamingType-label"
                      id="streamingType-select"
                      value={index}
                      label="Streaming or range data"
                      onChange={(event) => {
                        setIndex(event.target.value);
                      }}
                    >
                      <MenuItem value={"Depth"}>Depth</MenuItem>
                      <MenuItem value={"Time"}>Time</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack spacing={3} sx={{ mt: 2 }}>
                  <Grid container spacing={2} direction="row" justify="center">
                    {index === "Time" && (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="red">
                          Sorry, time index is not supported yet
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      {index === "Depth" ? (
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            autoComplete="start"
                            type="number"
                            label="Start depth"
                            value={range.start}
                            onChange={(event) => {
                              setRange({
                                start: event.target.value,
                                end: range.end,
                              });
                            }}
                          />
                        </FormControl>
                      ) : (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          disabled
                        >
                          <DateTimePicker
                            disabled
                            renderInput={(props) => <TextField {...props} />}
                            label="Start time"
                            value={range.start}
                            onChange={(newValue) => {
                              setRange({
                                start: newValue,
                                end: range.end,
                              });
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {index === "Depth" ? (
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            autoComplete="end"
                            type="number"
                            label="End depth"
                            value={range.end}
                            onChange={(event) => {
                              setRange({
                                start: range.start,
                                end: event.target.value,
                              });
                            }}
                          />
                        </FormControl>
                      ) : (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          disabled
                        >
                          <DateTimePicker
                            disabled
                            renderInput={(props) => <TextField {...props} />}
                            label="End time"
                            value={range.end}
                            onChange={(newValue) => {
                              setRange({
                                start: range.start,
                                end: newValue,
                              });
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    </Grid>
                  </Grid>
                </Stack>
              </>
            )}
            {(type !== "log" || !isStreaming) && (
              <>
                <Stack spacing={3}>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", my: 2 }}
                  >
                    Choose the attribute to display:
                  </Typography>
                </Stack>
                <Stack spacing={3}>
                  <Box>
                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        width: "100%",
                        maxHeight: "120px",
                        overflowY: "auto",
                      }}
                    >
                      {Object.keys(channels).map((key) => {
                        let channel = channels[key];
                        if (
                          (isStreaming && channel.allowStream) ||
                          (!isStreaming &&
                            channel.indexes &&
                            (channel.indexes.length === 2 ||
                              channel.indexes[0].indexType === index))
                        ) {
                          // if (!isStreaming || channel.allowStream) {
                          return (
                            <FormControlLabel
                              key={key}
                              control={
                                <Checkbox
                                  checked={channel.checked}
                                  disabled={index === "Time"}
                                  onChange={(event) => {
                                    setChannelsState(
                                      Object.assign({}, channelsState, {
                                        [key]: {
                                          name: channel.name,
                                          allowStream: channel.allowStream,
                                          checked: event.target.checked,
                                          channelId: channel.channelId,
                                        },
                                      })
                                    );
                                    // change the count
                                    if (event.target.checked) {
                                      setCountSelected(countSelected + 1);
                                    } else {
                                      setCountSelected(countSelected - 1);
                                    }
                                  }}
                                  value={channel.channelId}
                                />
                              }
                              label={channel.name}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    </FormGroup>
                  </Box>
                </Stack>
              </>
            )}
          </>
        )}
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={index === "Time"}
          sx={{ mt: 3 }}
          onClick={() => {
            if (
              type === "scaler" &&
              (defaultChannel === -1 || defaultChannel === "")
            ) {
              alert("Please select an attribute");
              return;
            }
            if (
              (type === "table" ||
                type === "chart" ||
                (type === "log" && !isStreaming)) &&
              (countSelected === 0 || countSelected === undefined)
            ) {
              alert("Please select at least one attribute");
              return;
            }
            if (
              (type === "table" || type === "chart" || type === "log") &&
              !isStreaming &&
              range.start >= range.end
            ) {
              alert("Start time must be before end time");
              return;
            }
            // console.log(channelsState);
            props.addItem({
              id: generateRandomString(5),
              title: title,
              type: type,
              defaultChannel: defaultChannel,
              channelsState: channelsState,
              isStreaming: isStreaming,
              range: range,
              index: index,
            });
            //clear the states
            reset();
            props.handleClose();
          }}
        >
          Add widget
        </Button>
      </ModalStyle>
    </ModalContainerStyle>
  );
}
