// react
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWellsNumber,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
} from "../components/_dashboard/app";
// storage
import * as storage from "../utils/storage";
// notification
import { useSnackbar } from "notistack";
// etp
// import etp from "etp";
import { Buffer } from "buffer";
import * as etp_tools from "../utils/etp_tools";
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [wellNumber, setWellNumber] = useState(0);
  const [ resources, setResources ] = useState({});
  useEffect(() => {
    enqueueSnackbar("Loading...", {
      variant: "info",
      persist: true,
      preventDuplicate: true,
    });
    const server = storage.getFromLS("server");
    // console.log("server", storage.getFromLS("server"));
    const etpHandler = (socket, msg_header, msg_message) => {
      // console.log("msg_header", msg_header);
      // console.log("msg_message", msg_message);
      if (msg_header.protocol === 3) {
        if (msg_header.messageType === 2) {
          var recource_name = msg_message.resource.name;
          resources[recource_name] = msg_message.resource;
          // add resource to resources
          setResources({
            resources
          });
          if (msg_header.messageFlags === 3) {
            // console.log("well number", resources.Well);
            setWellNumber(resources.Well.hasChildren);
            storage.saveToLS("resources", resources);
            closeSnackbar();
          }
        }
      }
    };
    if (server.url) {
      const socket = new WebSocket(server.url, "energistics-tp", server.ip, {
        "etp-encoding": "binary",
        Authorization:
          "Basic " + new Buffer.alloc(64, "user:pass").toString("base64"),
      });
      socket.onerror = function (msg) {
        enqueueSnackbar("Error connecting to the server", {
          variant: "error",
        });
        navigate("/login", { replace: true });
      };
      socket.onclose = function (msg) {
        enqueueSnackbar("Connection closed", {
          variant: "error",
        });
        navigate("/login", { replace: true });
      };
      socket.onopen = function (msg) {
        enqueueSnackbar("Wellcome Michael. You are connected to: " + server.ip, {
          autoHideDuration: 1500,
          preventDuplicate: true,
        });
        etp_tools.sendRequestSession(socket);
        etp_tools.sendGetRessource(socket, "eml://witsml20");
      };
      socket.onmessage = function (msg) {
        etp_tools.parseMsg(socket, msg, etpHandler)
      };
    } else {
      enqueueSnackbar("Server not found", {
        variant: "error",
      });
      navigate("/login", { replace: true });
    }
  }, []);

  return (
    <Page title="Dashboard | Alpha PGS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWellsNumber value={wellNumber}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports value={0} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
