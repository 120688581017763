// react
import React from "react";
import { useNavigate } from "react-router-dom";
// material
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Card,
  Typography,
  CardHeader,
  CardContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// utils
// storage
import * as storage from "../../../utils/storage";
// ----------------------------------------------------------------------

function processChannels(data) {
  if (data === {}) {
    return {};
  }
  // for key value pairs in data
  let channels = Object.keys(data).map((key) => {
    return {
      id: key,
      name: data[key].name,
      allowStream: true,
      minVal: -1,
      maxVal: -1,
    };
  });
  return channels;
}

export default function AppWellboreDataSelection(props) {
  const [rows, setRows] = React.useState(processChannels(props.data));
  const [showTrajectory, setShowTrajectory] = React.useState(
    props.showTraj.value
  );
  const [trajectory, setTrajectory] = React.useState({
    streaming: false,
    history: false,
  });
  React.useEffect(() => {
    setRows(processChannels(props.data));
    setShowTrajectory(props.showTraj.value);
  }, [props]);

  const navigate = useNavigate();
  return (
    <Card variant="outlined">
      <CardHeader title="Select channels " />
      <CardContent>
        <Typography variant="h6">Log data</Typography>
        {rows === {} ||
        rows === undefined ||
        rows === null ||
        rows.length === 0 ? (
          <Typography variant="body1">
            No data is not available for this wellbore.
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Channel</TableCell>
                  <TableCell align="left">Streaming</TableCell>
                  <TableCell align="left">Min value</TableCell>
                  <TableCell align="left">Max value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(rows).map((key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {rows[key].name}
                    </TableCell>
                    <TableCell align="left">
                      <Switch
                        checked={rows[key].allowStream}
                        onClick={() => {
                          setRows((prevState) => {
                            return {
                              ...prevState,
                              [key]: {
                                ...prevState[key],
                                allowStream: !prevState[key].allowStream,
                              },
                            };
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        type="number"
                        id="standard-basic"
                        label="Min value"
                        value={rows[key].minVal}
                        onChange={(e) => {
                          setRows((prevState) => {
                            return {
                              ...prevState,
                              [key]: {
                                ...prevState[key],
                                minVal: e.target.value,
                              },
                            };
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        type="number"
                        id="standard-basic"
                        label="Max value"
                        value={rows[key].maxVal}
                        onChange={(e) => {
                          setRows((prevState) => {
                            return {
                              ...prevState,
                              [key]: {
                                ...prevState[key],
                                maxVal: e.target.value,
                              },
                            };
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <br></br>
        <Typography variant="h6">Trajectory data</Typography>
        {showTrajectory ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Channel</TableCell>
                  <TableCell align="left">Streaming</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key="Trajectory"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Trajectory
                  </TableCell>
                  <TableCell align="left">
                    <Switch
                      checked={trajectory.streaming}
                      onChange={(e) => {
                        setTrajectory({
                          ...trajectory,
                          streaming: e.target.checked,
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1">
            Trajectory data is not available for this wellbore.
          </Typography>
        )}
        <br></br>
        <LoadingButton
          disabled={
            Object.keys(rows).length === 0 ||
            Object.keys(rows).length === undefined ||
            Object.keys(rows).length === null
          }

          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={() => {
            // save the rows and trajectory data to the store
            storage.saveToLS("streaming-config", {
              channels: rows,
              trajectory: trajectory,
            })
            // redirect to /app/monitor
            navigate("/app/monitor");
          }}
        >
          Go to monitoring board
        </LoadingButton>
      </CardContent>
    </Card>
  );
}
