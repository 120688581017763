// react stuff
// import { useState, useEffect } from "react";
// material
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

// sticky
import Sticky from "react-sticky-el";
// ----------------------------------------------------------------------
const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(1),
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const colorAndBackgroundColorGenerator = (index) => {
  // a list of none repeating colors that we can use to color our tree items
  const plate = [
    ["#e3742f", "#fcefe3"],
    ["#1a73e8", "#e8f0fe"],
    ["#a250f5", "#f3e8fd"],
    ["#3c8039", "#e6f4ea"],
  ];
  const color = plate[index % plate.length][0];
  const bgColor = plate[index % plate.length][1];
  return { color, bgColor };
};

export default function AppWellsTreeMap(props) {
  //set a counter to count the wells and wellbores
  let counter = 0;
  return (
    <Sticky stickyStyle={{ top: "10vh" }}>
      <TreeView
        key={props.data}
        aria-label="gmail"
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
      >
        {Object.entries(props.data).map(([well_key, well], index) => {
          const { color, bgColor } = colorAndBackgroundColorGenerator(index);
          const number_of_wellbores = Object.entries(well.wellbores).length;
          const wellbore_number_label =
            number_of_wellbores > 1 ? "wellbores" : "wellbore";
          return (
            <StyledTreeItem
              key={well_key}
              nodeId={(counter++).toString()}
              labelText={"Well:" + " " + well.name}
              labelIcon={InfoIcon}
              labelInfo={`${number_of_wellbores} ${wellbore_number_label}`}
              color={color}
              bgColor={bgColor}
              onClick={() => {
                props.selectData({
                  well: well.uri,
                  wellbore: null,
                });
              }}
            >
              {Object.entries(well.wellbores).map(
                ([wellbore_key, wellbore], index) => {
                  return (
                    <StyledTreeItem
                      key={wellbore_key}
                      nodeId={(counter++).toString()}
                      labelText={
                        wellbore.name + " " + wellbore.uuid.substring(0, 8)
                      }
                      labelIcon={WhatshotIcon}
                      onClick={() => {
                        props.selectData({
                          well: well.uri,
                          wellbore: wellbore.uri,
                        });
                      }}
                    />
                  );
                }
              )}
            </StyledTreeItem>
          );
        })}
      </TreeView>
    </Sticky>
  );
}
