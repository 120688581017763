// react
import React from "react";
// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Typography,
  Box,
  Button,
  Modal,
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Stack,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
// icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PhotoSizeSelectLargeRoundedIcon from "@mui/icons-material/PhotoSizeSelectLargeRounded";
import PhotoSizeSelectActualRoundedIcon from "@mui/icons-material/PhotoSizeSelectActualRounded";
import DensitySmallRoundedIcon from "@mui/icons-material/DensitySmallRounded";
import DensityLargeRoundedIcon from "@mui/icons-material/DensityLargeRounded";

import UnfoldLessRoundedIcon from "@mui/icons-material/UnfoldLessRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
// ----------------------------------------------------------------------
const ModalContainerStyle = styled(Modal)({
  position: "absolute",
  overflow: "scroll",
  height: "100%",
  display: "block",
});

const ModalStyle = styled(Card)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  backdropFilter: "blur(15px)",
  padding: theme.spacing(3.5),
  boxShadow: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "1px solid #c8bbf7",
}));

// ----------------------------------------------------------------------
const generateRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export default function ConfigBoard(props) {
  // States --------------------------------------------------------------
  const [numberOfColumns, setNumberOfColumns] = React.useState(12);
  const [rowHeight, setRowHeight] = React.useState(60);
  const [showHealth, setShowHealth] = React.useState(true);
  const [mute, setMute] = React.useState(false);
  // Functions ------------------------------------------------------------
  const reset = () => {};
  // UseEffects ------------------------------------------------------------
  React.useEffect(() => {
    // check if numberOfColumns is set
    if (props.numberOfColumns !== undefined) {
      setNumberOfColumns(props.numberOfColumns);
    }
    // check if rowHeight is set
    if (props.rowHeight !== undefined) {
      setRowHeight(props.rowHeight);
    }
  }, [props.numberOfColumns, props.rowHeight]);
  // Return ----------------------------------------------------------------
  return (
    <ModalContainerStyle
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalStyle>
        <Grid
          container
          spacing={0.5}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-center"
          sx={{ mb: 3 }}
        >
          <Typography variant="h6">Configure widgets board</Typography>
          <IconButton
            color="primary"
            component="span"
            onClick={() => {
              reset();
              props.handleClose();
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Grid>
        <Grid container spacing={2} direction="row" justify="center">
          <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label">
              Number of columns
            </InputLabel>
            <Stack
              spacing={2}
              direction="row"
              sx={{ my: 1 }}
              alignItems="center"
            >
              <PhotoSizeSelectLargeRoundedIcon />
              <Slider
                key="slider-number-of-columns"
                aria-label="Columns"
                value={numberOfColumns}
                valueLabelDisplay="auto"
                step={1}
                marks
                min={6}
                max={40}
                onChange={(e) => setNumberOfColumns(e.target.value)}
              />
              <PhotoSizeSelectActualRoundedIcon />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label">Row height</InputLabel>
            <Stack
              spacing={2}
              direction="row"
              sx={{ my: 1 }}
              alignItems="center"
            >
              <UnfoldLessRoundedIcon />
              <Slider
                key="slider-row-height"
                aria-label="Pixel"
                value={rowHeight}
                valueLabelDisplay="auto"
                step={10}
                marks
                min={20}
                max={200}
                onChange={(e) => setRowHeight(e.target.value)}
              />
              <UnfoldMoreRoundedIcon />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showHealth}
                    onChange={(e) => setShowHealth(e.target.checked)}
                    value={showHealth}
                  />
                }
                label={"Show health bar"}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mute}
                    onChange={(e) => {
                      setMute(e.target.checked);
                      props.setMute(e.target.checked);
                    }}
                    value={mute}
                  />
                }
                label={"Mute sound"}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          onClick={() => {
            props.setNumberOfColumns(numberOfColumns);
            props.setRowHeight(rowHeight);
            props.setShowHealth(showHealth);
            props.setMute(mute);
            //clear the states
            reset();
            props.handleClose();
          }}
        >
          Confirm changes
        </Button>
      </ModalStyle>
    </ModalContainerStyle>
  );
}
