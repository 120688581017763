/* eslint-disable array-callback-return */
// react
import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
// Styles --------------------------------------------------
const LogWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflow: "auto",
  backgroundColor: theme.palette.grey[300],
  padding: theme.spacing(0.7),
  borderRadius: theme.spacing(0.7),
  marginTop: theme.spacing(0.5),
}));
const LogLine = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontFamily: "monospace",
  marginBottom: theme.spacing(0.5),
  width: "100%",
  fontWeight: "lighter",
  textAlign: "left",
  whiteSpace: "nowrap",
  color: theme.palette.grey[800],
}));
export default function LogWidget(props) {
  // functions --------------------------------------------------
  const convertDataIntoLogs = (key, data, meta) => {
    let log = `${key} # `;
    Object.keys(data).map((index) => {
      log += `index: ${index} => `;
      Object.keys(data[index]).map((channelId) => {
        let channelName = meta.get(channelId);
        // console.log("channelName: ", channelName, "channelId: ", channelId, "meta: ", meta);
        log += `${channelName}: ${data[index][channelId]} `;
      });
    });
    return log;
  };
  // states -----------------------------------------------------
  const [logs, setLogs] = React.useState([]);
  const [textLogs, setTextLogs] = React.useState('');
  const [meta, setMeta] = React.useState({});
  // useEffect --------------------------------------------------
  React.useEffect(() => {
    // console.log("logs: ", props);
    setMeta(props.meta);
    let tmpLogs = [];
    // 
    // let tmpTextLogs = '';
    if (props.logs) {
      Object.keys(props.logs).map((key) => {
        tmpLogs.push(convertDataIntoLogs(key, props.logs[key], meta));
        // tmpTextLogs += convertDataIntoLogs(key, props.logs[key], meta) + "\n";
      });
    }
    setLogs(tmpLogs);
    // props.setDownloadableContent({
    //   type: 'text/plain',
    //   data: tmpTextLogs,
    // });
  }, [meta, props]);

  return (
    <LogWrapper>
      {logs.map((log, index) => {
        return <LogLine key={index}>{log}</LogLine>;
      })}
    </LogWrapper>
  );
}
