// react stuff
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// material
import { Box, Grid, Container, Typography, Button } from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppWellTrackerInfo,
  AppWellsTreeMap,
  AppWellDetails,
  AppWellboreDataSelection,
} from "../components/_dashboard/app";
// storage
import * as storage from "../utils/storage";
// notification
import { useSnackbar } from "notistack";
// etp
// import etp from "etp";
import { Buffer } from "buffer";
import * as etp_tools from "../utils/etp_tools";
// xml to json
import { convertXML } from "simple-xml-to-json";
//----------------------------------------------------------------------
export default function WellTracker() {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedObjects, setSelectedObject] = useState({});
  const [channelInfos, setChannelInfos] = useState({});
  const [wells, setWells] = useState({});
  const [wellInfoTitle, setWellInfoTitle] = useState(
    "Select a well to show its details"
  );
  const [loadingComplete, setLoadingComplete] = useState({});
  const [showContent, setShowContent] = useState(false);
  const [showTrajectory, setShowTrajectory] = useState({ value: false });
  const [wellInfo, setWellInfo] = useState({});
  const getShowableData = (json) => {
    const well = json.Well.children;
    var data = {};
    // loop through the well data
    for (var i = 0; i < well.length; i++) {
      // loop through the attributes of the well
      for (var key in well[i]) {
        if (well[i][key].content) {
          data[key] = well[i][key].content;
        }
      }
    }
    return data;
  };
  const etpHandler = (socket, msg_header, msg_message) => {
    if (msg_header.protocol === 3) {
      if (msg_header.messageType === 2) {
        //------------------------------------------------------------------------------------------------------
        if (msg_message.resource.resourceType === "Folder") {
          if (
            msg_message.resource.contentType ===
            "application/x-witsml+xml;version=2.0;type=Wellbore"
          ) {
            // get the first 58 character from the wellbore uri
            const well_uri = msg_message.resource.uri.substring(0, 57);
            // check if well[well_uri] does not exists or does not have attribute "Wellbores"
            if (!wells[well_uri]) {
              wells[well_uri] = {};
              wells[well_uri].wellbores = {};
            } else {
              if (!wells[well_uri].wellbores) {
                wells[well_uri].wellbores = {};
              }
            }
            if (msg_message.resource.hasChildren > 0) {
              etp_tools.sendGetRessource(socket, msg_message.resource.uri);
            } else {
              loadingComplete.waitingWells = loadingComplete.waitingWells - 1;
              setLoadingComplete(loadingComplete);
            }
          }
          //log------------------------------------------------------------------------------------------------------
          if (
            msg_message.resource.contentType ===
            "application/x-witsml+xml;version=2.0;type=Log"
          ) {
            if (msg_message.resource.hasChildren > 0) {
              etp_tools.sendGetRessource(socket, msg_message.resource.uri);
              enqueueSnackbar("Loading...", {
                variant: "info",
                persist: true,
                preventDuplicate: true,
              });
            } else {
              setChannelInfos({});
              loadingComplete.totalChannels = -1;
              setLoadingComplete(loadingComplete);
              closeSnackbar();
              enqueueSnackbar("No channels to be loaded", {
                duration: 1500,
                preventDuplicate: true,
              });
            }
          }
          //channelset------------------------------------------------------------------------------------------------------
          if (
            msg_message.resource.contentType ===
            "application/x-witsml+xml;version=2.0;type=ChannelSet"
          ) {
            if (msg_message.resource.hasChildren > 0) {
              etp_tools.sendGetRessource(socket, msg_message.resource.uri);
            } else {
              setChannelInfos({});
              loadingComplete.totalChannels = -1;
              setLoadingComplete(loadingComplete);
              closeSnackbar();
              enqueueSnackbar("No channels to be loaded", {
                duration: 1500,
                preventDuplicate: true,
              });
            }
          }
          // trajectory------------------------------------------------------------------------------------------------------
          if (
            msg_message.resource.contentType ===
            "application/x-witsml+xml;version=2.0;type=Trajectory"
          ) {
            if (
              !wells[selectedObjects.well].wellbores[selectedObjects.wellbore]
                .trajectories
            ) {
              wells[selectedObjects.well].wellbores[
                selectedObjects.wellbore
              ].trajectories = {};
            }
            if (msg_message.resource.hasChildren > 0) {
              etp_tools.sendGetRessource(socket, msg_message.resource.uri);
              setShowTrajectory({ value: true });
            }
          }
        }
        // channel------------------------------------------------------------------------------------------------------
        if (
          msg_message.resource.contentType ===
          "application/x-witsml+xml;version=2.0;type=Channel"
        ) {
          if (
            !wells[selectedObjects.well].wellbores[selectedObjects.wellbore]
              .channels
          ) {
            wells[selectedObjects.well].wellbores[
              selectedObjects.wellbore
            ].channels = {};
          }
          if (msg_message.resource.hasChildren > 0) {
            if (loadingComplete.totalChannels) {
              loadingComplete.totalChannels =
                loadingComplete.totalChannels +
                msg_message.resource.hasChildren;
            } else {
              loadingComplete.totalChannels = msg_message.resource.hasChildren;
            }
            setLoadingComplete(loadingComplete);
            etp_tools.sendGetRessource(socket, msg_message.resource.uri);
          }
        }
      }
      //------------------------------------------------------------------------------------------------------
      if (msg_message.resource.resourceType === "DataObject") {
        if (
          msg_message.resource.contentType ===
          "application/x-witsml+xml;version=2.0;type=Well"
        ) {
          // adding the well to wells state
          wells[msg_message.resource.uri] = msg_message.resource;
          setWells(wells);
          // check if loadingComplete has attribute waitingWells
          if (loadingComplete.waitingWells) {
            loadingComplete.waitingWells = loadingComplete.waitingWells + 1;
          } else {
            loadingComplete.waitingWells = 1;
          }
          if (msg_header.messageFlags === 3) {
            loadingComplete.wells = true;
          }
          setLoadingComplete(loadingComplete);
          etp_tools.sendGetRessource(socket, msg_message.resource.uri);
        }
        //------------------------------------------------------------------------------------------------------
        if (
          msg_message.resource.contentType ===
          "application/x-witsml+xml;version=2.0;type=Wellbore"
        ) {
          // get the first 58 character from the wellbore uri
          const well_uri = msg_message.resource.uri.substring(0, 57);
          // add the wellbore to the well[well_uri]
          wells[well_uri].wellbores[msg_message.resource.uri] =
            msg_message.resource;
          setWells(wells);
          storage.saveToLS("wells", wells);
          if (msg_header.messageFlags === 3) {
            loadingComplete.waitingWells = loadingComplete.waitingWells - 1;
            setLoadingComplete(loadingComplete);
            // ckeck if all wells are loaded and wells_finished is equal to 0
            if (
              loadingComplete.wells === true &&
              loadingComplete.waitingWells < 1
            ) {
              setShowContent(true);
              closeSnackbar();
              enqueueSnackbar(
                "We have loaded " + Object.keys(wells).length + " wells",
                {
                  duration: 1500,
                  preventDuplicate: true,
                }
              );
            }
          }
        }
        //LOG------------------------------------------------------------------------------------------------------
        if (
          msg_message.resource.contentType ===
          "application/x-witsml+xml;version=2.0;type=Log"
        ) {
          etp_tools.sendGetRessource(socket, msg_message.resource.uri);
        }
        //channelset------------------------------------------------------------------------------------------------------
        if (
          msg_message.resource.contentType ===
          "application/x-witsml+xml;version=2.0;type=ChannelSet"
        ) {
          etp_tools.sendGetRessource(socket, msg_message.resource.uri);
        }
        // trajectory------------------------------------------------------------------------------------------------------
        if (
          msg_message.resource.contentType ===
          "application/x-witsml+xml;version=2.0;type=Trajectory"
        ) {
        }
        // channel------------------------------------------------------------------------------------------------------
        if (
          msg_message.resource.contentType ===
          "application/x-witsml+xml;version=2.0;type=Channel"
        ) {
          wells[selectedObjects.well].wellbores[
            selectedObjects.wellbore
          ].channels[msg_message.resource.uri] = msg_message.resource;
          // channelCount represents the number of channels we received already
          if (loadingComplete.channelCount) {
            loadingComplete.channelCount = loadingComplete.channelCount + 1;
          } else {
            loadingComplete.channelCount = 1;
          }
          if (loadingComplete.channelCount === loadingComplete.totalChannels) {
            setWells(wells);
            storage.saveToLS("wells", wells);
            var chans =
              wells[selectedObjects.well].wellbores[selectedObjects.wellbore]
                .channels;

            setChannelInfos(
              wells[selectedObjects.well].wellbores[selectedObjects.wellbore]
                .channels
            );
            loadingComplete.channels = true;
            delete loadingComplete.totalChannels;

            closeSnackbar();
            enqueueSnackbar(
              "We have loaded " + loadingComplete.channelCount + " channels",
              {
                duration: 1500,
                preventDuplicate: true,
              }
            );
            loadingComplete.channelCount = 0;
          }

          setLoadingComplete(loadingComplete);
        }
      }
    }
    if (msg_header.protocol === 4) {
      if (msg_header.messageType === 4) {
        if (
          msg_message.dataObject.resource.contentType ===
          "application/x-witsml+xml;version=2.0;type=Well"
        ) {
          var string = new TextDecoder().decode(msg_message.dataObject.data);
          var json = convertXML(string);
          var data = getShowableData(json);
          wells[msg_message.dataObject.resource.uri].data = data;
          setWells(wells);
          storage.saveToLS("wells", wells);
          setWellInfo(data);
          setWellInfoTitle(
            "Well: " + wells[msg_message.dataObject.resource.uri].name
          );
        }
      }
    }
  };
  const socket = useRef(null);
  useEffect(() => {
    enqueueSnackbar("Loading...", {
      variant: "info",
      persist: true,
      preventDuplicate: true,
    });
    const server = storage.getFromLS("server");
    if (server.url) {
      socket.current = new WebSocket(server.url, "energistics-tp", server.ip, {
        "etp-encoding": "binary",
        Authorization:
          "Basic " + new Buffer.alloc(64, "user:pass").toString("base64"),
      });

      socket.current.onerror = function (msg) {
        enqueueSnackbar("Error connecting to the server", {
          variant: "error",
        });
        navigate("/login", { replace: true });
      };
      socket.current.onclose = function (msg) {
        enqueueSnackbar("Connection closed", {
          variant: "error",
        });
        navigate("/login", { replace: true });
      };
      socket.current.onopen = function (msg) {
        enqueueSnackbar(
          "Wellcome Michael. You are connected to: " + server.ip,
          {
            autoHideDuration: 1500,
            preventDuplicate: true,
          }
        );
        etp_tools.sendRequestSession(socket.current);
        etp_tools.sendGetRessource(socket.current, "eml://witsml20/Well");
      };
      socket.current.onmessage = function (msg) {
        etp_tools.parseMsg(socket.current, msg, etpHandler);
      };
    } else {
      enqueueSnackbar("Server not found", {
        variant: "error",
      });
      navigate("/login", { replace: true });
    }
  }, []);

  const prevselected = useRef();
  useEffect(() => {
    if (
      prevselected.current &&
      (prevselected.current.well !== selectedObjects.well ||
        prevselected.current.wellbore !== selectedObjects.wellbore)
    ) {
      if (selectedObjects.well !== null && selectedObjects.wellbore !== null) {
        if (
          wells[selectedObjects.well].wellbores[selectedObjects.wellbore]
            .channels
        ) {
          setChannelInfos(
            wells[selectedObjects.well].wellbores[selectedObjects.wellbore]
              .channels
          );
        } else {
          delete loadingComplete.totalChannels;
          loadingComplete.channelCount = 0;
          setLoadingComplete(loadingComplete);
          etp_tools.sendGetRessource(socket.current, selectedObjects.wellbore);
        }
        if (
          !wells[selectedObjects.well].wellbores[selectedObjects.wellbore]
            .trajectories
        ) {
          setShowTrajectory({ value: false });
        } else {
          setShowTrajectory({ value: true });
        }
      }
    }
    prevselected.current = selectedObjects;
    socket.current.onmessage = function (msg) {
      etp_tools.parseMsg(socket.current, msg, etpHandler);
    };
  }, [selectedObjects]);
  // change wellInfo and wellInfoTitle

  const loadChannelSets = () => {};

  const updateWellDataInfo = (object) => {
    const well = object.well;
    const wellbore = object.wellbore;
    setSelectedObject({
      well: well,
      wellbore: wellbore,
    });
    if (well !== "") {
      const cachesWell = wells[well];
      if (cachesWell.data) {
        setWellInfo(cachesWell.data);
        setWellInfoTitle("Well: " + cachesWell.name);
      } else {
        etp_tools.sendGetObject(socket.current, well);
      }
    } else {
      // no well is selected
    }
  };

  return (
    <Page title="Well tracker | Alpha PGS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Track your wells</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <AppWellTrackerInfo />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            {showContent ? (
              <AppWellsTreeMap data={wells} selectData={updateWellDataInfo} />
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Container>
              <Grid item xs={12} md={12} lg={12} sx={{ mb: 5 }}>
                <AppWellDetails well={wellInfo} title={wellInfoTitle} />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <AppWellboreDataSelection
                  data={channelInfos}
                  showTraj={showTrajectory}
                />
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
