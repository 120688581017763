// material
import { Card, Typography, CardHeader, CardContent } from "@mui/material";
// utils
// ----------------------------------------------------------------------

export default function AppWellDetails(props) {
  const well = props.well;
  // loop over the well attributes and display them
  return (
    <Card variant="outlined">
      <CardHeader title={props.title}/>
      <CardContent>
        {Object.keys(well).map((key, index) => {
          if (key === "WellDatum") {
            return "";
          }
          return (
            <Typography
              key={index}
              variant="body1"
              gutterBottom
              style={{
                marginBottom: "1rem",
              }}
            >
              <strong>{key}:</strong> {well[key] ? well[key] : "N/A"}
            </Typography>
          );
        })}
      </CardContent>
    </Card>
  );
}
