// React
import React, { useState, useRef, useEffect, Fragment } from "react";
// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Typography,
  Box,
  CardContent,
  Grid,
  FormControlLabel,
  Switch,
  Collapse,
} from "@mui/material";

import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";

const HealthBarStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  height: "100%",
  borderRadius: "5px",
}));

export default function HealthBar(props) {
  //const [alerts, setAlerts] = React.useState(props.alerts);
  const [showAlertNbr, setShowAlertNbr] = useState(false);
  const [colorBck, setColorBck] = useState("");
  const [outChannels, setOutChannels] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  React.useEffect(() => {
    if (Object.values(props.alerts)) {
      var nbrAlerts = 0;
      Object.values(props.alerts).forEach((n) => (nbrAlerts += n));
      //console.log("NBR", nbrAlerts)
      if (nbrAlerts > 0) {
        setShowAlertNbr(true);
        setColorBck(
          "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(247,0,0,1) 35%, rgba(255,0,69,0.01724439775910369) 90%);"
        );
        var channels = [];
        for (var chan in props.alerts) {
          // console.log("CHECK CHAN", chan);
          if (props.alerts[chan] === 1) {
            channels.push(props.meta[chan]);
          }
        }
        setOutChannels(channels);
      } else {
        setShowAlertNbr(false);
        setColorBck(
          "linear-gradient(90deg, rgba(0,156,23,1) 0%, rgb(133 217 101) 35%, rgba(255,255,255,1) 90%)"
        );
      }
    }
  }, []);

  React.useEffect(() => {
    if (Object.values(props.alerts)) {
      var nbrAlerts = 0;
      Object.values(props.alerts).forEach((n) => (nbrAlerts += n));
      //console.log("NBR", nbrAlerts)
      if (nbrAlerts > 0) {
        setShowAlertNbr(true);
        setColorBck(
          "linear-gradient(90deg, rgba(245 57 57) 0%, rgba(255,255,255,1) 100%)"
        );
        var channels = [];
        for (var chan in props.alerts) {
          if (props.alerts[chan] === 1) {
            channels.push(props.meta[chan]);
          }
        }
        setOutChannels(channels);
      } else {
        setShowAlertNbr(false);
        setColorBck(
          "linear-gradient(90deg, rgb(133 217 101) 0%, rgba(255,255,255,1) 100%)"
        );
      }
    }
  }, [props]);

  return (
    <>
      {props.showHealth && props.widgets !== 0 && (
        <Grid item xs={12} md={12} lg={12}>
          <HealthBarStyle sx={{ display: "flex" }} variant="outlined">
            <Box
              sx={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontSize: "1.5rem",
                fontWeight: "bold",
                padding: "0.5rem",
                background: colorBck,
              }}
            >
              {showAlertNbr ? (
                <>
                  <DangerousOutlinedIcon
                    sx={{
                      color: "green.500",
                      backgroundColor: "green.800",
                      fontSize: 50,
                    }}
                  />
                  <Typography variant="h6">Out of range data.</Typography>
                </>
              ) : (
                <>
                  <DoneAllOutlinedIcon
                    sx={{
                      color: "green.500",
                      backgroundColor: "green.800",
                      fontSize: 50,
                    }}
                  />
                  <Typography variant="h6">Looking good.</Typography>
                </>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 0.7 }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                {showAlertNbr ? (
                  <Typography variant="h6">
                    {" "}
                    Some channels are out of range.
                  </Typography>
                ) : (
                  <Typography component="div" variant="h5">
                    {" "}
                    Streaming data is withing the acceptable range.{" "}
                  </Typography>
                )}
                <FormControlLabel
                  control={<Switch checked={checked} onChange={handleChange} />}
                  label="Show more information"
                />
                <Collapse in={checked}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        sx={{
                          my: 1,
                        }}
                      >
                        Latest data package was in:{" "}
                        {props.lastPackage.timestamp}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        sx={{
                          my: 1,
                        }}
                      >
                        Latest index: {props.lastPackage.index}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        sx={{
                          my: 1,
                        }}
                      >
                        Total number of streaming data attributes:{" "}
                        {props.nbrOfChannels}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        sx={{
                          my: 1,
                        }}
                      >
                        Total number of received data packages:{" "}
                        {props.nbrOfPackages}
                      </Typography>
                    </Grid>
                  </Grid>
                </Collapse>

                {showAlertNbr && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        sx={{
                          my: 1,
                        }}
                      >
                        Attention is required to channels :{" "}
                        {outChannels.join(",  ")}.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
              <Box
                sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
              ></Box>
            </Box>
          </HealthBarStyle>
        </Grid>
      )}
    </>
  );
}
