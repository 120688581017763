export const getFromLS = (storage) => {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(storage)) || {};
    } catch (e) {
      return null;
    }
  }
  return ls;
};

export const saveToLS = (storage, data) => {
  if (global.localStorage) {
    global.localStorage.setItem(
      storage,
      JSON.stringify(data)
    );
  }
};
