/* eslint-disable array-callback-return */
// react
import React from "react";
// material

export default function ScalerWidget(props) {
  // states -----------------------------------------------------
  const [channelName, setChannelName] = React.useState(props.channelName);
  const [channelValue, setChannelValue] = React.useState(0);
  const [flash, setFlash] = React.useState(false);
  // functions --------------------------------------------------
  const latestObjectProperty = (obj) => {
    if (obj) {
      let keys = Object.keys(obj);
      return keys[keys.length - 1];
    }
  };
  // compact very large number
  const compactNumber = (num) => {
    if (num > 1000000000000) {
      return (num / 1000000000000).toFixed(1) + " T";
    } else if (num > 1000000000) {
      return (num / 1000000000).toFixed(1) + " B";
    } else if (num > 1000000) {
      return `${(num / 1000000).toFixed(2)} M`;
    } else if (num > 1000) {
      return `${(num / 1000).toFixed(2)} K`;
    } else {
      return num;
    }
  };
  //
  // useEffect --------------------------------------------------
  React.useEffect(() => {
    // console.log("scaler2 : ", props);
    setChannelName(props.channelName);
    if (props.data) {
      if (latestObjectProperty(props.data)) {
        var element = props.data[latestObjectProperty(props.data)];
        if (typeof element === "number") {
          setChannelValue(compactNumber(element));
        } else {
          setChannelValue(element);
        }
      } else {
        setChannelValue(0);
      }
    }
    setFlash(props.flash);
  }, [props]);

  // return ------------------------------------------------------
  return (
    <div className="scaler">
      <div className={flash ? "scaler-budget red" : "scaler-budget"}></div>
      <div className="scaler-content">
        <div className="scaler-title">{channelName}</div>
        <div className="scaler-value">{channelValue}</div>
      </div>
    </div>
  );
}
