// material
import { Card, Typography, CardHeader, CardContent } from "@mui/material";
// utils
// ----------------------------------------------------------------------

export default function AppWellTrackerInfo() {
  return (
    <Card
      variant="outlined"
      // set box shadow to nonde
      style={{
        boxShadow: "none"
      }}
    >
      <CardHeader title="Guide" />
      <CardContent>
        <Typography variant="Start by selecting the well"></Typography>
        <Typography variant="body1" gutterBottom>
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <li>Do thing number one</li>
          <li>Do thing number two</li>
          <li>Do thing number three</li>
        </Typography>
      </CardContent>
    </Card>
  );
}
