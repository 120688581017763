// material
import {
  Stack,
  Card,
  CardHeader,
  CardContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// utils
// ----------------------------------------------------------------------

export default function AppFormatConverterSettings() {
  return (
    <Card variant="outlined">
      <CardHeader title="Settings" />
      <CardContent>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="Setting 1"
            type="text"
            label="Setting 1"
          />
          <TextField
            fullWidth
            autoComplete="Setting 2"
            type="number"
            label="Setting 2"
          />
        </Stack>
        <br></br>
        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Process the files
        </LoadingButton>
      </CardContent>
    </Card>
  );
}
